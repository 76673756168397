package be.dvlopment.janssenstuinaanleg.models

import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.silk.theme.colors.ColorScheme
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.rgb
import org.jetbrains.compose.web.css.rgba

enum class Theme(
    val hex: String,
    val rgb: CSSColorValue
) {
    Primary(hex = "#488D57", rgb = rgb(r = 72, g = 141, b = 87)),
    Secondary(hex = "#000000", rgb = rgb(r = 0, g = 0, b = 0)),
    Gray(hex = "#CFCFCF", rgb = rgb(r = 207, g = 207, b = 207)),
    DarkGray(hex = "#878787", rgb = rgb(r = 135, g = 135, b = 135)),
    LightGray(hex = "#EDEDED", rgb = rgb(r = 237, g = 237, b = 237)),
    LighterGray(hex = "#F9F9F9", rgb = rgb(r = 249, g = 249, b = 249)),
    HalfBlack(hex = "#000000", rgb = rgba(r = 0, g = 0, b = 0 , 0.5f)),
    HalfPrimary(hex = "#488D57", rgb = rgba(r = 72, g = 141, b = 87, 0.4f))
}

val SwitchColorScheme = object : ColorScheme {
    override val _50 = Color.rgb(0XF2F2F2)
    override val _100 = Color.rgb(0XE6E6E6)
    override val _200 = Color.rgb(0X488D57)
    override val _300 = Color.rgb(0XB3B3B3)
    override val _400 = Color.rgb(0X999999)
    override val _500 = Color.rgb(0X808080)
    override val _600 = Color.rgb(0X666666)
    override val _700 = Color.rgb(0X488D57)
    override val _800 = Color.rgb(0X333333)
    override val _900 = Color.rgb(0X1A1A1A)
}