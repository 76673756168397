package be.dvlopment.janssenstuinaanleg.components.website

import androidx.compose.runtime.*
import androidx.compose.web.events.SyntheticMouseEvent
import be.dvlopment.janssenstuinaanleg.models.Picture
import be.dvlopment.janssenstuinaanleg.models.Theme
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.FaArrowLeft
import com.varabyte.kobweb.silk.components.icons.fa.FaArrowRight
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import kotlinx.browser.document
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.w3c.dom.events.Event
import org.w3c.dom.events.KeyboardEvent

@Composable
fun ImageGallery(
    selectedPicture: Picture,
    pictures: List<Picture>,
    onClose: () -> Unit
) {

    lateinit var handleKey: (Event) -> Unit
    handleKey = {
        if (it is KeyboardEvent) {
            println("keypress ${it.key}")
            if (it.key == "Escape") {
                onClose()
                document.removeEventListener("keydown", handleKey)
            }
        }
    }
    document.addEventListener("keydown", handleKey)


    var selectedIndex by remember {
        mutableStateOf(pictures.indexOf(selectedPicture))
    }

    fun decrease(event: SyntheticMouseEvent) {
        event.stopPropagation()
        if (selectedIndex != 0)
            selectedIndex--
    }

    fun increase(event: SyntheticMouseEvent) {
        event.stopPropagation()
        if (selectedIndex != pictures.size - 1)
            selectedIndex++
    }

    Box(
        modifier = Modifier
            .fillMaxSize()
            .position(Position.Fixed)
            .backgroundColor(Theme.HalfBlack.rgb)
            .onClick {
                onClose()
            },
        contentAlignment = Alignment.Center
    ) {
        Row(
            modifier = Modifier
                .padding(10.px)
                .fillMaxSize(),
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.SpaceBetween
        ) {
            FaArrowLeft(
                modifier = Modifier.onClick {
                    decrease(it)
                },
                size = IconSize.LG
            )

            Image(
                modifier = Modifier.fillMaxSize(70.percent)
                    .onClick {
                        increase(it)
                    },
                src = pictures[selectedIndex].path
            )

            FaArrowRight(
                modifier = Modifier.onClick {
                    increase(it)
                },
                size = IconSize.LG
            )
        }
    }
}