package be.dvlopment.janssenstuinaanleg.util

import be.dvlopment.janssenstuinaanleg.ApiResponse
import be.dvlopment.janssenstuinaanleg.format
import be.dvlopment.janssenstuinaanleg.models.*
import com.varabyte.kobweb.browser.api
import kotlinx.browser.window
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json


suspend fun checkUserExistence(user: User): UserWithoutPassword? {
    return try {
        val result = window.api.tryPost(
            apiPath = "userCheck",
            body = Json.encodeToString(user).encodeToByteArray()
        )
        result?.decodeToString()?.let { Json.decodeFromString(it) }
    } catch (e: Exception) {
        println(e.message)
        null
    }
}

suspend fun checkUserId(id: String): Boolean {
    return try {
        val result = window.api.tryPost(
            apiPath = "checkUserId",
            body = Json.encodeToString(id).encodeToByteArray()
        )
        result?.decodeToString()?.let { Json.decodeFromString(it) } ?: false
    } catch (e: Exception) {
        println(e.message.toString())
        false
    }
}

suspend fun addProject(project: Project): ApiResponse<Boolean> {
    return try {
        val result = window.api.tryPost(
            apiPath = "addproject",
            body = Json.encodeToString(project).encodeToByteArray()
        )?.decodeToString()
        if (result?.contains("errorMessage") == false)
            result.parseData<ApiResponse.Success<Boolean>>()
        else
            result.parseData<ApiResponse.Error>()
    } catch (e: Exception) {
        ApiResponse.Error(errorMessage = e.toString())
    }
}

suspend fun updateProject(project: Project): ApiResponse<Boolean> {
    return try {
        val result = window.api.tryPost(
            apiPath = "updateproject",
            body = Json.encodeToString(project).encodeToByteArray()
        )?.decodeToString()
        if (result?.contains("errorMessage") == false)
            result.parseData<ApiResponse.Success<Boolean>>()
        else
            result.parseData<ApiResponse.Error>()
    } catch (e: Exception) {
        ApiResponse.Error(errorMessage = e.toString())
    }
}

suspend fun sendMail(contactRequest: ContactRequest) : ApiResponse<Boolean> {
    return try {
        val result = window.api.tryPost(
            apiPath = "sendmail",
            body = Json.encodeToString(contactRequest).encodeToByteArray()
        )?.decodeToString()

        if (result?.contains("errorMessage") == false)
            result.parseData<ApiResponse.Success<Boolean>>()
        else
            result.parseData<ApiResponse.Error>()
    } catch (e: Exception) {
        ApiResponse.Error(e.message.toString())
    }
}

suspend fun deleteProjects(ids: List<String>): ApiResponse<Boolean> {
    return try {
        val result = window.api.tryPost(
            apiPath = "deleteprojects",
            body = Json.encodeToString(ids).encodeToByteArray()
        )?.decodeToString()

        if (result?.contains("errorMessage") == false)
            result.parseData<ApiResponse.Success<Boolean>>()
        else
            result.parseData<ApiResponse.Error>()
    } catch (e: Exception) {
        ApiResponse.Error(e.message.toString())
    }
}

suspend fun getProjects(): ApiResponse<List<Project>> {
    return try {
        val result = window.api.tryGet(apiPath = "projects")?.decodeToString()
        result.toString().parseData<ApiResponse.Success<List<Project>>>()
    } catch (e: Exception) {
        return ApiResponse.Error(errorMessage = "$e")
    }
}

suspend fun switchProjects(fromId: String, toId: String): ApiResponse<List<Project>> {
    return try {
        val result = window.api.tryPost(
            apiPath = "switchprojects",
            body = format.encodeToString(SwitchProjectsRequest(fromId, toId)).encodeToByteArray()
        )?.decodeToString()
        result.toString().parseData<ApiResponse.Success<List<Project>>>()
    } catch (e: Exception) {
        return ApiResponse.Error(errorMessage = e.toString())
    }
}

inline fun <reified T> String?.parseData(): T {
    return format.decodeFromString(this.toString())
}

suspend fun getProjectById(id: String): ApiResponse<Project> {
    return try {
        val result = window.api.tryGet(apiPath = "project?id=$id")?.decodeToString()
        format.decodeFromString<ApiResponse.Success<Project>>(result.toString())
    } catch (e: Exception) {
        return ApiResponse.Error(errorMessage = e.message.toString())
    }
}