package be.dvlopment.janssenstuinaanleg.util

import androidx.compose.runtime.*
import be.dvlopment.janssenstuinaanleg.navigation.Screen
import com.varabyte.kobweb.compose.file.LoadContext
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.outline
import com.varabyte.kobweb.core.rememberPageContext
import kotlinx.browser.document
import kotlinx.browser.localStorage
import kotlinx.browser.window
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.px
import org.khronos.webgl.ArrayBuffer
import org.khronos.webgl.Int8Array
import org.khronos.webgl.get
import org.w3c.dom.*
import org.w3c.dom.events.Event
import org.w3c.dom.events.EventListener
import org.w3c.files.Blob
import org.w3c.files.File
import org.w3c.files.FileReader
import org.w3c.xhr.ProgressEvent

@Composable
fun isUserLoggedIn(content: @Composable () -> Unit) {
    val context = rememberPageContext()
    val remembered = remember { localStorage["remember"].toBoolean() }
    val userId = remember { localStorage["userId"] }
    var userIdExists by remember { mutableStateOf(false) }

    LaunchedEffect(Unit) {
        userIdExists = if (!userId.isNullOrEmpty()) checkUserId(id = userId) else false
        if (!remembered || !userIdExists) {
            context.router.navigateTo(Screen.AdminLogin.route)
        }
    }

    if (remembered && userIdExists) {
        content()
    } else {
        println("Loading..")
    }
}

fun logout() {
    //clear al localstorage fields to false
    localStorage["remember"] = "false"
    localStorage["userId"] = ""
    localStorage["username"] = ""
}

fun Modifier.noBorder(): Modifier {
    return this.border(
        width = 0.px,
        style = LineStyle.None,
        color = Colors.Transparent
    ).outline(
        width = 0.px,
        style = LineStyle.None,
        color = Colors.Transparent
    )
}


/**private fun <I, O> Document.loadMultipleFromDisk(
accept: String = "",
triggerLoad: FileReader.(Blob) -> Unit,
deserialize: (I) -> O,
onLoading: (List<Pair<LoadContext, O>>) -> Unit,
) {
val tempInput = (createElement("input") as HTMLInputElement).apply {
type = "file"
style.display = "none"
this.accept = accept
multiple = true
}

tempInput.onchange = { changeEvt ->
val selectedFiles = changeEvt.target.asDynamic().files
val length = selectedFiles.length as Int
val loadedFiles = arrayListOf<Pair<LoadContext, O>>()

for (i in 0 until length) {
val reader = FileReader()
val file = selectedFiles[i] as File
reader.onload = { loadEvt ->
val result = loadEvt.target.asDynamic().result as I
loadedFiles.add(LoadContext(file.name, file.type.takeIf { it.isNotBlank() }) to deserialize(result))
onLoading(loadedFiles)
}
reader.triggerLoad(file)
}
}
body!!.append(tempInput)
tempInput.click()
tempInput.remove()
}

fun Document.loadMultipleDataUrlFromDisk(
accept: String = "",
onLoaded: (List<Pair<LoadContext, String>>) -> Unit,
) {
loadMultipleFromDisk<String, String>(
accept,
FileReader::readAsDataURL,
{ result -> result },
onLoaded
)
}*/

fun getSwitchText(count: Int) = count.toString() + " " + Res.String.items_selected


private fun Document.loadFromDisk(
    accept: String,
    multiple: Boolean,
    onChange: ((Event) -> dynamic)
) {
    val tempInput = (createElement("input") as HTMLInputElement).apply {
        type = "file"
        style.display = "none"
        this.accept = accept
        this.multiple = multiple
    }

    tempInput.onchange = onChange
    body!!.append(tempInput)
    tempInput.click()
    tempInput.remove()
}

class LoadedFile<T>(
    val context: LoadContext,
    val result: LoadResult<T>
) {
    sealed interface LoadResult<T> {
        class Success<T>(val contents: T) : LoadResult<T>
        sealed class Failure<T>(val event: ProgressEvent) : LoadResult<T>
        class Error<T>(event: ProgressEvent) : Failure<T>(event)
        class Abort<T>(event: ProgressEvent) : Failure<T>(event)
    }
}


private fun <I, O> Document.loadMultipleFromDisk(
    accept: String = "",
    triggerLoad: FileReader.(Blob) -> Unit,
    deserialize: (I) -> O,
    onLoading: (List<LoadedFile<O>>) -> Unit,
) {
    loadFromDisk(accept, multiple = true, onChange = { changeEvt ->
        val selectedFiles = changeEvt.target.asDynamic().files
        val length = selectedFiles.length as Int
        val loadedFiles = mutableListOf<LoadedFile<O>>()

        for (i in 0 until length) {
            val reader = FileReader()
            val file = selectedFiles[i] as File
            val context = LoadContext(file.name, file.type.takeIf { it.isNotBlank() })
            fun addLoadResult(loadResult: LoadedFile.LoadResult<O>) {
                loadedFiles.add(LoadedFile(context, loadResult))
                if (loadedFiles.size == length) {
                    onLoading(loadedFiles)
                }
            }

            reader.onabort = { addLoadResult(LoadedFile.LoadResult.Abort(it as ProgressEvent)) }
            reader.onerror = { addLoadResult(LoadedFile.LoadResult.Error(it as ProgressEvent)) }
            reader.onload = { loadEvt ->
                val result = loadEvt.target.asDynamic().result as I
                addLoadResult(LoadedFile.LoadResult.Success(deserialize(result)))
            }
            reader.triggerLoad(file)
        }
    })
}

fun Document.loadMultipleFromDisk(
    accept: String = "",
    onLoaded: (List<LoadedFile<ByteArray>>) -> Unit,
) {
    loadMultipleFromDisk<ArrayBuffer, ByteArray>(
        accept,
        FileReader::readAsArrayBuffer,
        { result ->
            val intArray = Int8Array(result)
            ByteArray(intArray.byteLength) { i -> intArray[i] }
        },
        onLoaded
    )
}

fun Document.loadMultipleDataUrlFromDisk(
    accept: String = "",
    onLoaded: (List<LoadedFile<String>>) -> Unit,
) {
    loadMultipleFromDisk<String, String>(
        accept,
        FileReader::readAsDataURL,
        { result -> result },
        onLoaded
    )
}

fun Document.loadMultipleTextFromDisk(
    accept: String = "",
    encoding: String = "UTF-8",
    onLoaded: (List<LoadedFile<String>>) -> Unit,
) {
    loadMultipleFromDisk<String, String>(
        accept,
        { file -> this.readAsText(file, encoding) },
        { result -> result },
        onLoaded
    )
}

fun <T> MutableList<T>.swap(i: Int, j: Int) {
    val t = this[i]
    this[i] = this[j]
    this[j] = t
}


@Composable
fun ObserveViewportEnteredContinious(
    elementId: String,
    whenEnter: (DOMRect?) -> Boolean,
    onViewPortEntered: (Boolean) -> Unit
) {
    var viewPortEntered by remember {
        mutableStateOf(false)
    }
    val listener = remember {
        EventListener {
            //top for section has passed into screen
            viewPortEntered = whenEnter(document.getElementById(elementId)?.getBoundingClientRect())
        }
    }
    LaunchedEffect(Unit){
        window.addEventListener(type = "scroll", callback = listener)
    }

    LaunchedEffect(viewPortEntered) {
        onViewPortEntered(viewPortEntered)
    }
}

@Composable
fun ObserveViewportEntered(
    elementId: String,
    distanceFromTop: Double,
    onViewPortEntered: () -> Unit
) {
    var viewPortEntered by remember {
        mutableStateOf(false)
    }
    val listener = remember {
        EventListener {
            val top = document.getElementById(elementId)?.getBoundingClientRect()?.top
            //top for section has passed into screen
            if (top != null && top < distanceFromTop) {
                viewPortEntered = true
            }
        }
    }

    LaunchedEffect(viewPortEntered) {
        if (viewPortEntered) {
            onViewPortEntered()
            window.removeEventListener(type = "scroll", callback = listener)
        } else {
            window.addEventListener(type = "scroll", callback = listener)
        }
    }
}

