package be.dvlopment.janssenstuinaanleg

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
abstract class ApiResponse<out T : Any?> {

    @Serializable
    @SerialName("idle")
    data object Idle : ApiResponse<Nothing>()

    @Serializable
    @SerialName("success")
    data class Success<out T>(val data: T) : ApiResponse<T>()

    @Serializable
    @SerialName("error")
    data class Error(val errorMessage: String) : ApiResponse<Nothing>()
}
