package be.dvlopment.janssenstuinaanleg.models

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient

@Serializable
data class Picture(
    @SerialName("_id")
    val _id : String? = null,
    val path : String = "",
    val content : String ? = null,
    val isHeader : Boolean = false
)