package be.dvlopment.janssenstuinaanleg.util

object Constants {
    const val WEBSITE = ""
    const val PAGE_WIDTH = 1920
    const val PAGE_HEIGHT = 1080
    const val SIDE_PANEL_WIDTH = 250
    const val HEADER_HEIGHT = 100
    const val COLLAPSED_PANEL_HEIGHT = 100
    const val FONT_FAMILY = "Rubik"
    const val LINKEDIN = ""
}

object Res {
    object Icon {
        const val web = "web_icon.svg"
        const val seo = "seo_icon.svg"
        const val design = "design_icon.svg"
        const val business = "business_icon.svg"
        const val apple = "apple_icon.svg"
        const val android = "android_icon.svg"
        const val link = "link_icon.svg"
        const val user = "user_icon.svg"
        const val shield = "shield_icon.svg"
        const val happy = "happy_icon.svg"
        const val checkmark = "checkmark_icon.svg"
        const val star = "star_icon.svg"
    }

    object Image {
        const val home = "/picture_home.jpg"
        const val logo = "/janssens_tuinaanleg_logo.png"
        const val klinkerwerken = "/picture_klinkerwerken.jpg"
        const val tuinaanleg = "/picture_tuinaanleg.jpg"
        const val groenaanleg = "/picture_groenaanleg.jpg"
        const val kleine_verbouwing = "/picture_kleine_verbouwing.jpg"
        const val over = "/picture_over.jpg"
        const val over_small = "/picture_over_small.jpg"
    }

    object String {


        const val home = "Home"
        const val portfolio = "Portfolio"
        const val activities = "Activiteiten"
        const val about = "Over"
        const val contact = "Contact"
        const val signout = "Uitloggen"

        //Admin index
        const val mode_select = "Selecteren"
        const val items_selected = "items geselecteerd"
        const val delete = "Verwijderen"
        const val no_projects_found =
            "Je hebt op het moment nog geen projecten. Je kan deze altijd aanmaken via Project Aanmaken"


        //Admin create
        const val title = "Titel"
        const val description = "Omschrijving"
        const val architect = "Architect"
        const val location = "Locatie"
        const val type = "Type van werk"
        const val date = "Datum"
        const val pictures = "Kies afbeeldingen"
        const val create = "Project Aanmaken"
        const val save_changes = "Aanpassingen opslaan"
        const val uploaden = "Uploaden"
        const val requiredFieldsError = "Vergeet niet alle verplichte velden in te vullen!"


        //Admin create success
        const val createSuccessTitle = "Project werd succesvol toegevoegd aan portfolio"
        const val go_to_home = "Ga naar home"


        //Contact form
        const val name = "Naam"
        const val name_placeholder = "Volledige Naam"
        const val email = "Email"
        const val email_placeholder = "Email adres"
        const val phone = "Telefoon"
        const val phone_placeholder = "Uw telefoonnummer"
        const val message = "Bericht"
        const val message_placeholder = "Uw bericht"
        const val residence = "Adres"
        const val residence_placeholder = "Straat nummer postcode stad"

        const val contactTitle = "Hier kan je altijd vrijblijvend contact opnemen"
        const val contactSend = "Verstuur"

        //contact success
        const val contactSuccessTitle = "Uw aanvraag werd goed ontvangen."
        const val contactSuccessMessage =
            "Bedankt voor uw interesse in Janssens Tuinaanleg. We nemen zo snel mogelijk contact met u op."


        //contact block copy
        const val contactCompanyName = "Janssens Tuinaanleg"
        const val contactAddressLine1 = "Minstraat 27"
        const val contactAddressLine2 = "3540 Schulen (Herk-de-Stad)"
        const val contactPhonenumber = "+32 478 28 38 63"
        const val contactBlockTitle = "Contact"
        const val btwNumber = "BE0799.916.042"
        const val all_rights_reserved = "Alle rechten voorbehouden © 2023 Janssens Tuinaanleg"

        //Activities titles

        const val activities_klinkerwerken = "Klinkerwerken"
        const val activities_companyGardens = "Bedrijfstuinen"
        const val activities_groen_aanleg = "Groenaanleg"
        const val activities_wadi = "Wadi"
        const val activities_grondwerken = "Grondwerken"
        const val activities_scheiden_riolering = "Scheiden van rioleringen"
        const val activities_kleine_verbouwing = "Kleine verbouwingen"
        const val activities_onderhoud_sportvelden = "Onderhoud van sportvelden en grasterreinen"

        const val activities_gardenDesign = "Tuinaanleg"

        //Activities descriptions

        //garden design
        const val activities_gardenDesign_description =
            "Bij Janssens Tuinaanleg geloven we dat elke buitenruimte het potentieel heeft om een adembenemende, functionele en rustgevende omgeving te worden. Ons team van ervaren tuinarchitecten staat klaar om jouw dromen om te zetten in een weelderige realiteit. Of je nu verlangt naar een serene plek om te ontspannen na een drukke dag, een levendige en kleurrijke tuin om gasten te verwelkomen, of een praktische buitenruimte voor entertainment - wij hebben de kennis en creativiteit om jouw visie tot leven te brengen. Van het selecteren van de juiste beplanting en materialen tot het ontwerpen van een lay-out die perfect past bij jouw levensstijl, wij nemen elk aspect van tuinaanleg serieus.\n" +
                    "Wat maakt ons uniek? \n \n" +
                    "Onze toewijding aan kwaliteit, vakmanschap en klanttevredenheid. Wij werken samen met jou om een op maat gemaakt ontwerp te creëren dat niet alleen esthetisch aantrekkelijk is, maar ook functioneel en duurzaam. Ons deskundig oog zorgt ervoor dat elk element gedetailleerd en precies van jouw tuin met zorg wordt geïnstalleerd. Neem vandaag nog contact met ons op en ontdek de mogelijkheden van professionele tuinaanleg met een persoonlijke touch. Jouw droomtuin wacht op je!"


        const val activities_unique = "Wat maakt ons uniek?"
        const val activities_unique_description = "Onze toewijding aan kwaliteit, vakmanschap en klanttevredenheid. Wij werken samen met jou om een op maat gemaakt ontwerp te creëren dat niet alleen esthetisch aantrekkelijk is, maar ook functioneel en duurzaam. Ons deskundig oog zorgt ervoor dat elk element gedetailleerd en precies van jouw tuin met zorg wordt geïnstalleerd. Neem vandaag nog contact met ons op en ontdek de mogelijkheden van professionele tuinaanleg met een persoonlijke touch. Jouw droomtuin wacht op je"
        const val activities_partners = "Partners"
        const val activities_partners_description = "Daarnaast kunnen we bij Janssens Tuinaanleg ook een totaalproject aanbieden met een tuinontwerp en eventueel een zwembad of -vijver door middel van een samenwerking met onze vaste partners. Zo kunnen we het geheel ook gefaseerd uitvoeren naargelang budget."

        //klinker werken
        const val activities_klinkerwerken_description =
            "Wil je jouw buitenruimte omtoveren tot een sfeervolle en duurzame plek? Ontdek de magie van hoogwaardige klinkerwerken! Bij ons staan vakmanschap en kwaliteit centraal, en we zijn er trots op om jouw droomproject te realiseren."

        const val activities_groenaanleg_description = "Wij bij Janssens Tuinaanleg begrijpen dat de buitenomgeving van jouw woning, bedrijf of openbare ruimte een cruciale rol speelt in de algehele uitstraling en functionaliteit. Onze groenaanlegdiensten gaan verder dan simpelweg het planten van bloemen en bomen; wij creëren levendige, duurzame en doelgerichte groenruimtes die aansluiten op jouw visie en behoeften.\n" +
                    "Onze aanpak start met een grondige consultatie op locatie om jouw visie, voorkeuren en functionele behoeften te begrijpen. Nadien wordt er een op maat gemaakt ontwerp voorgesteld dat rekening houdt met de uniciteit van jouw tuin. Onze bloemen, planten en bomen worden geleverd door onze vaste partners, waardoor wij onze kwaliteit kunnen garanderen. Daarnaast staan wij ook garant voor een kwaliteitsvolle aanleg met oog voor detail en duurzaamheid. Deze duurzaamheid tekent zich ook door het kiezen voor inheemse soorten, waterbesparende technieken en milieuvriendelijke materialen, zodat wij samen met u een positieve impact hebben op het milieu. \n"

        const val activities_services = "Onze diensten:"
        const val activities_driveway = "Oprit en terras aanleggen:"
        const val activities_driveway_description = "Creëer een warm welkom of een ontspannen buitenruimte met een perfect aangelegde oprit en terras."
        const val activities_garden_paths = "Tuinpaden en borders:"
        const val activities_garden_paths_description = "Voeg structuur en stijl toe aan jouw tuin met zorgvuldig ontworpen paden en borders."
        const val activities_renovations = "Herstellingen en renovaties:"
        const val activities_renovations_description = "Breng nieuw leven in bestaande klinkerwerken met onze herstellings- en renovatiediensten."


        const val activities_wadi_description = "Sinds 2 oktober 2023 is het voor nieuwe bouwaanvragen verplicht om een groter buffervolume en infiltratieoppervlakte van de verplichte infiltratievoorziening te voorzien. De ideale oplossing hiervoor is de wadi. De wadi staat garant voor een duurzame oplossing voor het waterbeheer. Een wadi is een natuurlijke of kunstmatige waterafvoer die is ontworpen om regenwater te verzamelen, te vertragen en in de bodem te laten infiltreren. Deze innovatieve oplossing draagt niet alleen bij aan het verminderen van overstromingsrisico's, maar bevordert ook de natuurlijke watercyclus en verbetert de kwaliteit van het lokale aquatisch milieu. Wij ontwerpen samen met u een een wadi die past bij de specifieke behoeften van uw terrein, rekening houdend met de waterstroom, het landschap en de omgevingsfactoren. "
        const val activities_grondwerken_description = "Elk succesvol project begint met een solide basis. Als toonaangevende aannemer bieden wij u gespecialiseerde grondwerkoplossingen aan. Zo staan wij klaar om als ervaren grondwerker de werken met de nodige precisie uit te voeren. Dit kan zowel voor algemene uitgravingen, wadi’s, bouwputten, funderingen, rioleringssystemen als zwembaden. Wij zorgen voor een vlotte en efficiënte start van uw project. Daarnaast bieden we ook nauwkeurige nivellerings- en aanvullingswerken aan om ervoor te zorgen dat uw project een optimale start kent. \n"
        const val activities_scheiden_riolering_description = "Gescheiden riolering betekent het creëren van twee afzonderlijke systemen: één voor regenwater en één voor afvalwater. Het regenwatersysteem transporteert het water rechtstreeks naar natuurlijke waterwegen of wordt opgeslagen voor hergebruik, terwijl het afvalwatersysteem zorgt voor de behandeling en zuivering van huishoudelijk en industrieel afvalwater."
        const val activities_kleine_verbouwing_description = "Of je nu je huis wilt moderniseren, extra ruimte wilt creëren of gewoon een frisse wind door je leefruimte wilt laten waaien, verbouwen is de sleutel tot het realiseren van jouw droomwoning.\n" +
                "\tOnze diensten houden vooral het steken van poutrels en balken, breek- en metselwerken en het storten van beton in. Hierin beschikken we over de nodige ervaring en materialen.\n"
        const val activities_onderhoud_sportvelden_description = "Het onderhoud van sportvelden en grasterreinen is essentieel om optimale speelcondities te behouden en de levensduur van het terrein te verlengen. Hier zijn enkele diensten die we aanbieden voor het onderhoud van zowel sportvelden als grasterreinen:"

        const val activities_grass = "Gras maaien:"
        const val activities_grass_description = "Regelmatig maaien is van cruciaal belang om een gelijkmatige graslengte te behouden en een gezond speeloppervlak te bevorderen."
        const val activities_bemesting = "Bemesting:"
        const val activities_bemesting_description = "Het toepassen van geschikte meststoffen zorgt voor een gezonde grasgroei en verbetert de algehele kwaliteit van het veld."
        const val activities_beluchting = "Beluchting:"
        const val activities_beluchting_description = "Door het beluchten van het veld kan zuurstof, water en voedingsstoffen gemakkelijker doordringen tot de wortels van het gras, waardoor de gezondheid van het gazon wordt bevorderd.\n"
        const val activities_verticuteren = "Verticuteren:"
        const val activities_verticuteren_description = "Het verwijderen van overtollig dood gras en mossen door verticuteren bevordert een betere luchtcirculatie en grasgroei.\n"
        const val activities_doorzaaien = "Doorzaaien:"
        const val activities_doorzaaien_description = "Deze techniek wordt vaak gebruikt om de dichtheid van het gewas te vergroten, kale plekken op te vullen of om nieuwe en gewenste plantensoorten te introduceren. Doorzaaien wordt toegepast in verschillende contexten, waaronder landbouw, graslandbeheer, sportvelden en gazons."



        //About
        const val about_intro =
            "Bij Janssens Tuinaanleg is het creëren van prachtige buitenruimtes niet alleen een beroep, maar een diepgewortelde passie. Als tuinaannemer ben ik gepassioneerd door elke fase van mijn werk, van het ontwerp tot de uiteindelijke realisatie."
        const val about_creative_experience_title = "De creatieve uitdaging:"
        const val about_creative_experience_description =
            " Elk project begint als een blanco canvas. Het is mijn creatieve uitdaging om de wensen van mijn klanten te vertalen naar een uniek en doordacht ontwerp. Het spelen met kleuren, texturen en de natuurlijke elementen brengt een gevoel van artistieke vervulling dat mijn werk buitengewoon bevredigend maakt."
        const val about_durability_title = "Duurzaamheid en milieubewustzijn:"
        const val about_durability_description =
            "Als tuinaannemer heb ik de mogelijkheid om bij te dragen aan duurzame praktijken. Van het selecteren van inheemse plantensoorten tot het implementeren van milieuvriendelijke ontwerpen, ik zie het als mijn verantwoordelijkheid om bij te dragen aan een gezond milieu voor de huidige en toekomstige generaties.\n" +
                    "Bij Janssens Tuinaanleg gaat het niet alleen om het aanleggen van tuinen; het gaat om het cultiveren van leefruimtes die emoties en herinneringen koesteren. Elke dag ben ik dankbaar dat ik mijn passie voor groen kan delen en transformeren in levende kunstwerken die langdurige vreugde brengen aan de mensen die ervan genieten.\n"


    }
}

object Id {
    //admin login
    const val usernameInput = "usernameInput"
    const val passwordInput = "passwordInput"

    //sidepanel
    const val sidePanelFaIcon = "faIcon"
    const val sidePanelItemText = "sidePanelItemText"

    //admin input fields
    const val titleInput = "usernameInput"
    const val descriptionInput = "descriptionInput"
    const val architectInput = "architextInput"
    const val locationInput = "locationInput"
    const val typeInput = "typeInput"
    const val dateInput = "dateInput"
    const val headerImageInput = "headerImageInput"

    //contact form
    const val emailInput = "emailInput"
    const val nameInput = "nameInput"
    const val residenceInput = "residenceInput"
    const val phoneInput = "phoneInput"
    const val messageInput = "messageInput"
}