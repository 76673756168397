package be.dvlopment.janssenstuinaanleg.pages.admin

import androidx.compose.runtime.*
import be.dvlopment.janssenstuinaanleg.ApiResponse
import be.dvlopment.janssenstuinaanleg.components.admin.AdminPageLayout
import be.dvlopment.janssenstuinaanleg.components.admin.AdminProjectGrid
import be.dvlopment.janssenstuinaanleg.format
import be.dvlopment.janssenstuinaanleg.models.Theme
import be.dvlopment.janssenstuinaanleg.pages.ErrorMessage
import be.dvlopment.janssenstuinaanleg.util.*
import be.dvlopment.janssenstuinaanleg.util.Constants.FONT_FAMILY
import com.varabyte.kobweb.browser.api
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.Visibility
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.forms.Switch
import com.varabyte.kobweb.silk.components.forms.SwitchSize
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import kotlinx.browser.window
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Button
import androidx.compose.runtime.mutableStateListOf
import be.dvlopment.janssenstuinaanleg.components.LoadingSpinner
import be.dvlopment.janssenstuinaanleg.models.Project
import be.dvlopment.janssenstuinaanleg.models.SwitchColorScheme
import com.varabyte.kobweb.core.rememberPageContext
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.percent

@Page
@Composable
fun HomePage() {
    isUserLoggedIn {
        HomeScreen()
    }
}

@Composable
fun HomeScreen() {
    val breakpoint = rememberBreakpoint()
    val scope = rememberCoroutineScope()
    val context = rememberPageContext()

    var apiResponse by remember {
        mutableStateOf<ApiResponse<List<Project>>>(ApiResponse.Idle)
    }

    var errorState by remember {
        mutableStateOf<String?>(null)
    }

    var projects by remember(apiResponse) {
        mutableStateOf(arrayListOf<Project>())
    }

    LaunchedEffect(apiResponse) {
        val response = apiResponse
        if (response is ApiResponse.Success<List<Project>>)
            projects = response.data as ArrayList<Project>
    }

    var selectableMode by remember { mutableStateOf(false) }
    var switchText by remember { mutableStateOf(Res.String.mode_select) }
    val selectedProjects = remember { mutableStateListOf<String>() }

    LaunchedEffect(Unit) {
        scope.launch {
            apiResponse = getProjects()
        }
    }

    AdminPageLayout {
        Box(
            modifier = Modifier
                .fillMaxSize(),
            contentAlignment = Alignment.TopCenter
        ) {
            Column(
                modifier = Modifier.fillMaxSize()
                    .padding(left = if (breakpoint > Breakpoint.MD) Constants.SIDE_PANEL_WIDTH.px else 0.px)
            ) {
                Row(
                    modifier = Modifier.fillMaxWidth().padding(topBottom = 5.px, leftRight = 10.px),
                    horizontalArrangement = Arrangement.SpaceBetween,
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    Row(modifier = Modifier.fillMaxWidth(), verticalAlignment = Alignment.CenterVertically) {
                        Switch(
                            modifier = Modifier.margin(right = 8.px),
                            size = SwitchSize.LG,
                            checked = selectableMode,
                            onCheckedChange = {
                                selectableMode = it
                                if (!selectableMode) {
                                    switchText = Res.String.mode_select
                                    selectedProjects.clear()
                                } else {
                                    switchText = Res.String.items_selected
                                }
                            },
                            colorScheme = SwitchColorScheme
                        )
                        SpanText(
                            modifier = Modifier.color(if (selectableMode) Colors.Black else Theme.HalfBlack.rgb),
                            text = switchText
                        )
                    }

                    Button(
                        attrs = Modifier
                            .height(54.px)
                            .padding(leftRight = 24.px)
                            .backgroundColor(Theme.Primary.rgb)
                            .color(Colors.White)
                            .noBorder()
                            .borderRadius(r = 4.px)
                            .fontFamily(FONT_FAMILY)
                            .fontSize(14.px)
                            .fontWeight(FontWeight.Medium)
                            .visibility(if (selectedProjects.isNotEmpty()) Visibility.Visible else Visibility.Hidden)
                            .onClick {
                                scope.launch {
                                    val result = deleteProjects(ids = selectedProjects)
                                    when (result) {
                                        is ApiResponse.Success<Boolean> -> {
                                            selectableMode = false
                                            switchText = Res.String.mode_select
                                            selectedProjects.forEach { deletedPostId ->
                                                projects.removeAll {
                                                    it._id == deletedPostId
                                                }
                                            }
                                            selectedProjects.clear()
                                            errorState = null
                                        }

                                        is ApiResponse.Error -> errorState = result.errorMessage
                                    }
                                }
                            }
                            .toAttrs()
                    ) {
                        SpanText(text = Res.String.delete)
                    }
                }
                HomeBody(apiResponse,
                    selectableMode,
                    onEdit = { projectId ->
                        context.router.navigateTo(
                            "project/${projectId}/edit"
                        )
                    },
                    onSelect = {
                        selectedProjects.add(it)
                        switchText = getSwitchText(selectedProjects.count())
                    },
                    onDeselect = {
                        selectedProjects.remove(it)
                        switchText = getSwitchText(selectedProjects.count())
                    },
                    switchProjects = { draggedId, droppedOnId ->
                        scope.launch {
                            apiResponse = switchProjects(draggedId, droppedOnId)
                        }
                    })
            }
        }
    }
}


@Composable
private fun HomeBody(
    apiResponse: ApiResponse<List<Project>>,
    selectableMode: Boolean,
    onEdit: (String) -> Unit,
    onSelect: (String) -> Unit,
    onDeselect: (String) -> Unit,
    switchProjects: (draggedId: String, droppedOnId: String) -> Unit
) {
    Box(modifier = Modifier.fillMaxWidth(), contentAlignment = Alignment.Center) {
        when (apiResponse) {
            is ApiResponse.Error -> ErrorMessage(error = apiResponse)
            is ApiResponse.Idle -> LoadingSpinner()
            is ApiResponse.Success -> AdminProjectGrid(
                apiResponse.data,
                selectableMode,
                onEdit,
                onSelect,
                onDeselect,
                switchProjects
            )
        }
    }
}

