package be.dvlopment.janssenstuinaanleg.pages

import androidx.compose.runtime.*
import be.dvlopment.janssenstuinaanleg.ApiResponse
import be.dvlopment.janssenstuinaanleg.components.LoadingSpinner
import be.dvlopment.janssenstuinaanleg.components.website.ImageGallery
import be.dvlopment.janssenstuinaanleg.components.website.PageLayout
import be.dvlopment.janssenstuinaanleg.components.website.PictureGrid
import be.dvlopment.janssenstuinaanleg.format
import be.dvlopment.janssenstuinaanleg.models.Picture
import be.dvlopment.janssenstuinaanleg.models.Project
import be.dvlopment.janssenstuinaanleg.models.Theme
import be.dvlopment.janssenstuinaanleg.util.Constants
import be.dvlopment.janssenstuinaanleg.util.getProjectById
import com.varabyte.kobweb.browser.api
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.ObjectFit
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import kotlinx.browser.window
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text

@Page("project/{id}")
@Composable
fun ProjectDetailPage() {

    val ctx = rememberPageContext()
    val portfolioItemId = ctx.route.params.getValue("id")
    var apiResponse by remember {
        mutableStateOf<ApiResponse<Project>>(ApiResponse.Idle)
    }

    val scope = rememberCoroutineScope()

    var selectedPicture by remember {
        mutableStateOf<Picture?>(null)
    }

    val images = remember(apiResponse) {
        mutableStateListOf<Picture>()
    }

    LaunchedEffect(apiResponse) {
        if (apiResponse is ApiResponse.Success)
            images.addAll((apiResponse as ApiResponse.Success<Project>).data.pictures)
    }

    LaunchedEffect(Unit) {
        scope.launch {
            apiResponse = getProjectById(portfolioItemId)
        }
    }

    PageLayout {
        when (val response = apiResponse) {
            is ApiResponse.Error -> Text(response.errorMessage)
            is ApiResponse.Idle -> LoadingSpinner()
            is ApiResponse.Success -> PortfolioContent(response.data){
                selectedPicture = it
            }
        }
    }

    selectedPicture?.let {
        ImageGallery(selectedPicture = it, pictures = images, onClose = {
            selectedPicture = null
        })
    }
}

@Composable
private fun PortfolioContent(
    project: Project,
    onPictureClicked: (Picture) -> Unit
) {
    val breakpoint = rememberBreakpoint()

    Column(
        modifier = Modifier.fillMaxWidth(if (breakpoint >= Breakpoint.MD) 70.percent else 90.percent)
    ) {
        Image(
            modifier = Modifier.fillMaxWidth().aspectRatio(5 / 2).objectFit(ObjectFit.Cover),
            src = project.pictures.firstOrNull { it.isHeader }?.path ?: "",
            alt = "Header picture"
        )

        P(
            attrs = Modifier
                .margin(left = 0.px, right = 0.px, top = 10.px, bottom = 0.px)
                .fontFamily(Constants.FONT_FAMILY)
                .fontSize(20.px)
                .fontWeight(FontWeight.Light)
                .color(Theme.Secondary.rgb)
                .textAlign(TextAlign.Center)
                .toAttrs()
        ) {
            Text(
                value = project.title
            )
        }

        P(
            attrs = Modifier
                .margin(topBottom = 5.px)
                .fontFamily(Constants.FONT_FAMILY)
                .fontSize(18.px)
                .fontWeight(FontWeight.Light)
                .color(Theme.Secondary.rgb)
                .toAttrs()
        ) {
            Text(
                value = project.location + " - " + project.date
            )
        }

        P(
            attrs = Modifier
                .fillMaxWidth()
                .margin(top = 0.px, bottom = 15.px)
                .fontFamily(Constants.FONT_FAMILY)
                .fontSize(15.px)
                .fontWeight(FontWeight.Light)
                .color(Theme.Secondary.rgb)
                .lineHeight(15.px)
                .toAttrs {
                    ref {
                        it.innerHTML = project.htmlDescription
                        onDispose { }
                    }
                }
        )

        PictureGrid(
            portFolioItems = project.pictures,
            onPictureClick = {
                onPictureClicked(it)
            }
        )
    }
}








