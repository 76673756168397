package be.dvlopment.janssenstuinaanleg

import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.json.Json
import kotlinx.serialization.modules.SerializersModule
import kotlinx.serialization.modules.polymorphic
import kotlinx.serialization.modules.subclass

private val responseModule = SerializersModule {
    polymorphic(ApiResponse::class) {
        subclass(ApiResponse.Success.serializer(PolymorphicSerializer(Any::class)))
        subclass(ApiResponse.Error.serializer())
        subclass(ApiResponse.Idle.serializer())
    }
}

val format = Json {
    classDiscriminator = "#class"
    serializersModule = responseModule
}


