package be.dvlopment.janssenstuinaanleg.components.website

import androidx.compose.runtime.*
import be.dvlopment.janssenstuinaanleg.models.Project
import be.dvlopment.janssenstuinaanleg.models.Theme
import be.dvlopment.janssenstuinaanleg.styles.PortfolioSectionStyle
import be.dvlopment.janssenstuinaanleg.util.Constants
import be.dvlopment.janssenstuinaanleg.util.ObserveViewportEnteredContinious
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextDecorationLine
import com.varabyte.kobweb.compose.css.Visibility
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.navigation.OpenLinkStrategy
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toModifier
import kotlinx.browser.window
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text

@Composable
fun PortfolioGrid(
    breakpoint: Breakpoint,
    portFolioItems: List<Project>?
) {
    SimpleGrid(
        modifier = Modifier
            .fillMaxWidth()
            .rowGap(10.px)
            .columnGap(10.px),
        numColumns = numColumns(base = 1, md = 2, lg = 3)
    ) {
        portFolioItems?.forEach {
            if (breakpoint > Breakpoint.MD)
                ProjectCard(it)
            else
                ProjectCardForMobile(it)
        }
    }
}

@Composable
fun ProjectCard(project: Project) {
    Link(
        modifier = PortfolioSectionStyle
            .toModifier()
            .maxHeight(300.px)
            .textDecorationLine(TextDecorationLine.None),
        path = "/project/${project._id}",
        openExternalLinksStrategy = OpenLinkStrategy.IN_NEW_TAB
    ) {

        Box(
            modifier = Modifier
                .id("boxParent")
                .aspectRatio(width = 3, height = 2)
                .margin(bottom = 20.px)
        ) {
            Image(
                modifier = Modifier.fillMaxSize(),
                src = project.pictures.firstOrNull { it.isHeader }?.path ?: "",
                alt = "Header Picture of portfolio ${project._id}"
            )

            Column(
                modifier = Modifier
                    .id("greenOverlay")
                    .fillMaxWidth()
                    .backgroundColor(Theme.HalfPrimary.rgb),
                horizontalAlignment = Alignment.CenterHorizontally,
                verticalArrangement = Arrangement.Center
            ) {
                P(
                    attrs = Modifier
                        .id("portfolioItemInfo")
                        .margin(0.px)
                        .fontFamily(Constants.FONT_FAMILY)
                        .fontSize(18.px)
                        .fontWeight(FontWeight.Normal)
                        .color(Colors.White)
                        .toAttrs()
                ) {
                    Text(
                        project.title
                    )
                }

                P(
                    attrs = Modifier
                        .id("portfolioItemInfo")
                        .margin(top = 10.px, bottom = 0.px, leftRight = 0.px)
                        .fontFamily(Constants.FONT_FAMILY)
                        .fontSize(12.px)
                        .fontWeight(FontWeight.Normal)
                        .color(Colors.White)
                        .toAttrs()
                ) {
                    Text(
                        project.location
                    )
                }
            }
        }
    }
}

@Composable
fun ProjectCardForMobile(project: Project) {

    val elementId = "projectBlock${project._id}"
    val scope = rememberCoroutineScope()
    var isCurrentBlock by remember {
        mutableStateOf(false)
    }

    ObserveViewportEnteredContinious(
        elementId = elementId,
        whenEnter = {
            val middleOfScreen = window.innerHeight / 2
            println("middleOfScreen : $middleOfScreen -- bottom : ${it?.bottom} -- top : ${it?.top}")
            (it?.bottom ?: 0.0) > middleOfScreen && (it?.top ?: 0.0) < middleOfScreen
        }
    ) {
        scope.launch {
            isCurrentBlock = it
            println("trigger for ${project._id}")
        }
    }

    Link(
        modifier = Modifier
            .id(elementId)
            .fillMaxWidth()
            .textDecorationLine(TextDecorationLine.None),
        path = "/project/${project._id}",
        openExternalLinksStrategy = OpenLinkStrategy.IN_NEW_TAB
    ) {

        Box(
            modifier = Modifier
                .fillMaxWidth()
                .aspectRatio(width = 3, height = 2)
                .margin(bottom = 20.px)
        ) {
            Image(
                modifier = Modifier.fillMaxSize(),
                src = project.pictures.firstOrNull { it.isHeader }?.path ?: "",
                alt = "Header Picture of portfolio ${project._id}"
            )

            Column(
                modifier = Modifier
                    .fillMaxWidth()
                    .height(0.px)
                    .thenIf(isCurrentBlock) { Modifier.fillMaxHeight() }
                    .backgroundColor(Theme.HalfPrimary.rgb),
                horizontalAlignment = Alignment.CenterHorizontally,
                verticalArrangement = Arrangement.Center
            ) {
                P(
                    attrs = Modifier
                        .margin(0.px)
                        .visibility(if(isCurrentBlock) Visibility.Visible else Visibility.Hidden)
                        .fontFamily(Constants.FONT_FAMILY)
                        .fontSize(18.px)
                        .fontWeight(FontWeight.Normal)
                        .color(Colors.White)
                        .toAttrs()
                ) {
                    Text(
                        project.title
                    )
                }

                P(
                    attrs = Modifier
                        .margin(top = 10.px, bottom = 0.px, leftRight = 0.px)
                        .visibility(if(isCurrentBlock) Visibility.Visible else Visibility.Hidden)
                        .fontFamily(Constants.FONT_FAMILY)
                        .fontSize(12.px)
                        .fontWeight(FontWeight.Normal)
                        .color(Colors.White)
                        .toAttrs()
                ) {
                    Text(
                        project.location
                    )
                }
            }
        }
    }
}