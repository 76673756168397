package be.dvlopment.janssenstuinaanleg.pages

import androidx.compose.runtime.*
import be.dvlopment.janssenstuinaanleg.ApiResponse
import be.dvlopment.janssenstuinaanleg.components.LoadingSpinner
import be.dvlopment.janssenstuinaanleg.components.website.OverflowMenu
import be.dvlopment.janssenstuinaanleg.components.website.PageLayout
import be.dvlopment.janssenstuinaanleg.components.website.PortfolioGrid
import be.dvlopment.janssenstuinaanleg.models.Project
import be.dvlopment.janssenstuinaanleg.models.Theme
import be.dvlopment.janssenstuinaanleg.navigation.Screen
import be.dvlopment.janssenstuinaanleg.util.Constants
import be.dvlopment.janssenstuinaanleg.util.getProjects
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text

@Page()
@Composable
fun PortfolioPage() {
    val breakpoint = rememberBreakpoint()
    val scope = rememberCoroutineScope()

    var apiResponse by remember {
        mutableStateOf<ApiResponse<List<Project>>>(ApiResponse.Idle)
    }

    LaunchedEffect(Unit) {
        scope.launch {
            apiResponse = getProjects()
        }
    }

    var menuOpened by remember {
        mutableStateOf(false)
    }

    PageLayout(screen = Screen.Portfolio) {
        Box(
            modifier = Modifier
                .maxWidth(if (breakpoint >= Breakpoint.MD) 70.percent else 90.percent),
            contentAlignment = Alignment.Center
        ) {
            when (val response = apiResponse) {
                is ApiResponse.Error -> ErrorMessage(error = response)
                is ApiResponse.Idle -> LoadingSpinner()
                is ApiResponse.Success -> PortfolioGrid(breakpoint, response.data)
            }
        }
    }
    if (menuOpened) {
        OverflowMenu(onMenuClosed = {
            menuOpened = false
        })
    }
}

@Composable
fun ErrorMessage(error: ApiResponse.Error) {
    P(
        attrs = Modifier.fillMaxWidth()
            .margin(topBottom = 0.px)
            .fontFamily(Constants.FONT_FAMILY)
            .fontSize(30.px)
            .fontWeight(FontWeight.Bolder)
            .color(Theme.Primary.rgb)
            .textAlign(TextAlign.Center)
            .toAttrs()
    ) {
        Text(
            error.errorMessage
        )
    }
}



