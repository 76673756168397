package be.dvlopment.janssenstuinaanleg.pages

import androidx.compose.runtime.*
import be.dvlopment.janssenstuinaanleg.ApiResponse
import be.dvlopment.janssenstuinaanleg.components.website.ContactForm
import be.dvlopment.janssenstuinaanleg.components.website.PageLayout
import be.dvlopment.janssenstuinaanleg.models.Theme
import be.dvlopment.janssenstuinaanleg.navigation.Screen
import be.dvlopment.janssenstuinaanleg.util.Constants
import be.dvlopment.janssenstuinaanleg.util.Res
import be.dvlopment.janssenstuinaanleg.util.sendMail
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text

@Page
@Composable
fun ContactPage() {
    val breakpoint = rememberBreakpoint()
    val scope = rememberCoroutineScope()
    val context = rememberPageContext()

    var sendMailResponse by remember {
        mutableStateOf<ApiResponse<Boolean>>(ApiResponse.Success(false))
    }

    var errorMessage by remember {
        mutableStateOf<String?>(null)
    }

    PageLayout(Screen.Contact) {

        Column(
            modifier = Modifier
                .padding(topBottom = 25.px)
                .fillMaxWidth(if (breakpoint > Breakpoint.MD) 70.percent else 100.percent),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            P(
                attrs = Modifier
                    .margin(bottom = 25.px)
                    .maxWidth(500.px)
                    .fontFamily(Constants.FONT_FAMILY)
                    .fontSize(20.px)
                    .fontWeight(FontWeight.Light)
                    .color(Theme.Secondary.rgb)
                    .toAttrs()

            ) {
                Text(
                    Res.String.contactTitle
                )
            }

            errorMessage?.let {
                be.dvlopment.janssenstuinaanleg.pages.admin.ErrorMessage(it)
            }

            ContactForm(
                breakpoint = breakpoint,
                sendMailResponse
            ) {
                scope.launch {
                    sendMailResponse = ApiResponse.Idle
                    val result = sendMail(it)
                    when (result) {
                        is ApiResponse.Success<Boolean> -> if (result.data) context.router.navigateTo("/contact/success")
                        is ApiResponse.Error -> errorMessage = result.errorMessage
                    }
                    sendMailResponse = result
                }
            }
        }
    }
}

