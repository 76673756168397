package be.dvlopment.janssenstuinaanleg.site

import kotlin.String

internal object BuildConfig {
  internal const val MONGODB_URI: String =
      "mongodb+srv://dietervaesen:ebO44bmHz5kCgDOK@cluster0.cdmlwro.mongodb.net/"

  internal const val CONTACT_EMAIL: String = "janssenstuinaanleg@gmaiL.com"

  internal const val MAIL_FROM_ACCOUNT: String = "contact@janssens-tuinaanleg.be"

  internal const val MAIL_ACCOUNT_USERNAME: String = "contact@janssens-tuinaanleg.be"

  internal const val MAIL_ACCOUNT_PASSWORD: String = "3ebjhrgFHzQP4h86LVhd"

  internal const val STORAGE_BUCKET: String = "janssens-tuinaanleg.appspot.com"

  internal const val FIREBASE_STORAGE_ROOT_FOLDER: String = "project-files"
}
