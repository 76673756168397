package be.dvlopment.janssenstuinaanleg.components.admin

import androidx.compose.runtime.*
import be.dvlopment.janssenstuinaanleg.models.Project
import be.dvlopment.janssenstuinaanleg.models.Theme
import be.dvlopment.janssenstuinaanleg.util.Constants
import be.dvlopment.janssenstuinaanleg.util.Res
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.AlignItems
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.CheckboxInput
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text

@Composable
fun AdminProjectGrid(
    projects: List<Project>,
    selectableMode: Boolean,
    onEdit: (String) -> Unit,
    onSelect: (String) -> Unit,
    onDeselect: (String) -> Unit,
    switchProjects: (draggedId: String, droppedOnId: String) -> Unit
) {
    if (projects.isNotEmpty()) {
        SimpleGrid(
            modifier = Modifier
                .id("scrollableContainer")
                .fillMaxSize()
                .padding(all = 10.px)
                .rowGap(10.px)
                .columnGap(10.px),
            numColumns = numColumns(base = 1, md = 2, lg = 3)
        ) {
            projects.forEach {
                AdminProjectCard(it, selectableMode, onEdit, onSelect, onDeselect, switchProjects)
            }
        }
    } else {
        Div(attrs = Modifier.fillMaxSize().display(DisplayStyle.Grid).alignItems(AlignItems.Center).toAttrs()) {
            SpanText(
                modifier = Modifier.fillMaxWidth().textAlign(TextAlign.Center),
                text = Res.String.no_projects_found
            )
        }
    }
}

@Composable
fun AdminProjectCard(
    project: Project,
    selectableMode: Boolean,
    onEdit: (String) -> Unit,
    onSelect: (String) -> Unit,
    onDeselect: (String) -> Unit,
    switchProjects: (draggedId: String, droppedOnId: String) -> Unit
) {
    var checked by remember(selectableMode) { mutableStateOf(false) }

    fun manageSelection(projectId: String) {
        checked = !checked
        if (checked) {
            onSelect(project._id)
        } else {
            onDeselect(project._id)
        }
    }

    Box(
        modifier = Modifier
            .fillMaxWidth()
            .draggable(true)
            .onDragStart {
                //triggered on item thats being dragged
                it.dataTransfer?.setData("projectId", project._id)
                println("ondragstart -- ${project._id}")
            }
            .onDragOver {
                //triggered on item that we are dragging on
                it.preventDefault()
            }
            .onDrop {
                //triggered on item thats being dragged
                it.preventDefault()
                val draggedId = it.dataTransfer!!.getData("projectId")
                val droppedOnId = project._id
                switchProjects(draggedId, droppedOnId)
            }
            .aspectRatio(width = 3, height = 2)
            .onClick {
                if (!selectableMode)
                    onEdit(project._id)
                else
                    manageSelection(project._id)
            }
    ) {

        Image(
            modifier = Modifier.fillMaxSize(),
            src = project.pictures.firstOrNull { it.isHeader }?.path ?: "",
            alt = "Picture of Admin project ${project._id}"
        )

        Column(
            modifier = Modifier
                .fillMaxSize()
                .backgroundColor(Theme.HalfPrimary.rgb),
            horizontalAlignment = Alignment.CenterHorizontally,
            verticalArrangement = Arrangement.Center
        ) {
            P(
                attrs = Modifier
                    .margin(0.px)
                    .fontFamily(Constants.FONT_FAMILY)
                    .fontSize(18.px)
                    .fontWeight(FontWeight.Normal)
                    .color(Colors.White)
                    .toAttrs()
            ) {
                Text(
                    project.title
                )
            }

            P(
                attrs = Modifier
                    .margin(top = 10.px, bottom = 0.px, leftRight = 0.px)
                    .fontFamily(Constants.FONT_FAMILY)
                    .fontSize(12.px)
                    .fontWeight(FontWeight.Normal)
                    .color(Colors.White)
                    .toAttrs()
            ) {
                Text(
                    project.location
                )
            }
        }
        if (selectableMode) {
            CheckboxInput(
                checked = checked,
                attrs = Modifier
                    .size(20.px)
                    .align(Alignment.TopEnd)
                    .margin(top = 10.px, right = 10.px)
                    .toAttrs(),
            )
        }
    }
}