package be.dvlopment.janssenstuinaanleg.models

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
actual class User(
    actual val _id: String = "",
    actual val username: String = "",
    actual val password: String = ""
)

@Serializable
actual class UserWithoutPassword(
    actual val _id: String = "",
    actual val username: String = ""
)