package be.dvlopment.janssenstuinaanleg.models

import kotlinx.serialization.Serializable

@Serializable
actual class ContactRequest(
    actual val name : String = "",
    actual val email : String = "",
    actual val residence : String = "",
    actual val phone : String = "",
    actual val message : String = ""
)