package be.dvlopment.janssenstuinaanleg.components.admin

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize

@Composable
fun AdminPageLayout(content : @Composable ()-> Unit) {
    var overFlowMenuOpened by remember { mutableStateOf(false) }
    Column(modifier = Modifier.fillMaxSize()) {
        AdminSidePanel(
            onMenuClick = {
                overFlowMenuOpened = true
            }
        )
        if (overFlowMenuOpened)
            AdminOverflowSidePanel(onMenuClose = {
                overFlowMenuOpened = false
            })

        content()
    }
}