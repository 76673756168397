package be.dvlopment.janssenstuinaanleg.components.website

import androidx.compose.runtime.Composable
import be.dvlopment.janssenstuinaanleg.ApiResponse
import be.dvlopment.janssenstuinaanleg.components.LoadingSpinner
import be.dvlopment.janssenstuinaanleg.models.ContactRequest
import be.dvlopment.janssenstuinaanleg.models.Theme
import be.dvlopment.janssenstuinaanleg.styles.InputStyle
import be.dvlopment.janssenstuinaanleg.styles.MainButtonStyle
import be.dvlopment.janssenstuinaanleg.util.Constants.FONT_FAMILY
import be.dvlopment.janssenstuinaanleg.util.Id
import be.dvlopment.janssenstuinaanleg.util.Res
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.attrsModifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toModifier
import kotlinx.browser.document
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.HTMLTextAreaElement

@Composable
fun ContactForm(
    breakpoint: Breakpoint,
    apiResponse: ApiResponse<Boolean>,
    submitContactForm: (ContactRequest) -> Unit
) {
    //https://formspree.io/
    SimpleGrid(
        numColumns(1, md = 2),
        modifier = Modifier.fillMaxWidth(if (breakpoint >= Breakpoint.MD) 100.percent else 90.percent).columnGap(10.px)
    ) {
        Div {
            Label(
                attrs = Modifier
                    .fontFamily(FONT_FAMILY)
                    .classNames("form-label")
                    .toAttrs()
            ) {
                Text(Res.String.name)
            }
            Input(
                type = InputType.Text,
                attrs = InputStyle.toModifier()
                    .id(Id.nameInput)
                    .classNames("form-control")
                    .fontWeight(FontWeight.Light)
                    .fontFamily(FONT_FAMILY)
                    .margin(bottom = 10.px)
                    .attrsModifier {
                        attr("placeholder", Res.String.name_placeholder)
                        attr("required", "true")
                        attr("name", "name")
                    }
                    .backgroundColor(Theme.LighterGray.rgb)
                    .toAttrs(),
            )
        }

        Div {
            //email
            Label(
                attrs = Modifier.fontFamily(FONT_FAMILY).classNames("form-label").toAttrs()
            ) {
                Text(Res.String.email)
            }

            Input(
                type = InputType.Email,
                attrs = InputStyle.toModifier()
                    .id(Id.emailInput)
                    .classNames("form-control")
                    .fontWeight(FontWeight.Light)
                    .fontFamily(FONT_FAMILY)
                    .margin(bottom = 10.px)
                    .attrsModifier {
                        attr("placeholder", Res.String.email_placeholder)
                        attr("required", "true")
                        attr("name", "email")
                    }
                    .backgroundColor(Theme.LighterGray.rgb)
                    .toAttrs(),
            )
        }

        Div {
            //Residence
            Label(
                attrs = Modifier.fontFamily(FONT_FAMILY).classNames("form-label").toAttrs()
            ) {
                Text(Res.String.residence)
            }

            Input(
                type = InputType.Text,
                attrs = InputStyle.toModifier()
                    .id(Id.residenceInput)
                    .classNames("form-control")
                    .fontWeight(FontWeight.Light)
                    .fontFamily(FONT_FAMILY)
                    .margin(bottom = 10.px)
                    .attrsModifier {
                        attr("placeholder", Res.String.residence_placeholder)
                        attr("required", "true")
                        attr("name", "residence")
                    }
                    .backgroundColor(Theme.LighterGray.rgb)
                    .toAttrs(),
            )
        }

        Div {
            //phone
            Label(
                attrs = Modifier
                    .fontFamily(FONT_FAMILY)
                    .classNames("form-label").toAttrs()
            ) {
                Text(Res.String.phone)
            }

            Input(
                type = InputType.Tel,
                attrs = InputStyle.toModifier()
                    .id(Id.phoneInput)
                    .classNames("form-control")
                    .fontWeight(FontWeight.Light)
                    .fontFamily(FONT_FAMILY)
                    .margin(bottom = 10.px)
                    .attrsModifier {
                        attr("placeholder", Res.String.phone_placeholder)
                        attr("required", "true")
                        attr("name", "phone")
                    }
                    .backgroundColor(Theme.LighterGray.rgb)
                    .toAttrs(),
            )
        }


        Div(
            attrs = Modifier
                .fillMaxWidth()
                .gridColumn(1, if (breakpoint >= Breakpoint.LG) 3 else 1).toAttrs()
        ) {
            //Message
            Label(
                attrs = Modifier.fontFamily(FONT_FAMILY).classNames("form-label").toAttrs()
            ) {
                Text(Res.String.message)
            }

            TextArea(
                attrs = InputStyle.toModifier()
                    .id(Id.messageInput)
                    .classNames("form-control")
                    .fontWeight(FontWeight.Light)
                    .fontFamily(FONT_FAMILY)
                    .margin(bottom = 10.px)
                    .height(150.px).attrsModifier {
                        attr("placeholder", Res.String.message_placeholder)
                        attr("required", "true")
                        attr("name", "message")
                    }
                    .backgroundColor(Theme.LighterGray.rgb)
                    .toAttrs()
            )
        }

        if (apiResponse == ApiResponse.Idle) {
            LoadingSpinner()
        } else {
            Button(
                attrs = MainButtonStyle.toModifier()
                    .height(40.px)
                    .border(width = 0.px)
                    .borderRadius(r = 5.px)
                    .backgroundColor(Theme.Primary.rgb)
                    .color(Colors.White)
                    .cursor(Cursor.Pointer)
                    .onClick {
                        val name = (document.getElementById(Id.nameInput) as HTMLInputElement).value
                        val email = (document.getElementById(Id.emailInput) as HTMLInputElement).value
                        val residence = (document.getElementById(Id.residenceInput) as HTMLInputElement).value
                        val phone = (document.getElementById(Id.phoneInput) as HTMLInputElement).value
                        val message = (document.getElementById(Id.messageInput) as HTMLTextAreaElement).value

                        submitContactForm(
                            ContactRequest(
                                name,
                                email,
                                residence,
                                phone,
                                message
                            )
                        )


                    }
                    .toAttrs()
            ) {
                Text(Res.String.contactSend)
            }
        }
    }
}