package be.dvlopment.janssenstuinaanleg.pages

import androidx.compose.runtime.Composable
import be.dvlopment.janssenstuinaanleg.components.website.ArticleBlockItem
import be.dvlopment.janssenstuinaanleg.components.website.PageLayout
import be.dvlopment.janssenstuinaanleg.models.ArticleBlock
import be.dvlopment.janssenstuinaanleg.navigation.Screen
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.css.percent


@Page
@Composable
fun ActivitiesPage() {
    val breakpoint = rememberBreakpoint()
    PageLayout(screen = Screen.Activities) {
        Column(
            modifier = Modifier.fillMaxWidth(if (breakpoint > Breakpoint.MD) 70.percent else 90.percent)
        ) {
            ArticleBlock.activities.forEachIndexed { index, articleBlock ->
                ArticleBlockItem(articleBlock)
            }
        }
    }
}


