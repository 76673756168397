package be.dvlopment.janssenstuinaanleg.components.website

import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import be.dvlopment.janssenstuinaanleg.constants.htmlDescription
import be.dvlopment.janssenstuinaanleg.models.ArticleBlock
import be.dvlopment.janssenstuinaanleg.models.Theme
import be.dvlopment.janssenstuinaanleg.util.Constants
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text

@Composable
fun ArticleBlockItem(
    aboutBlock: ArticleBlock,
    layer: Int = 0
) {
    val breakpoint = rememberBreakpoint()
    val titleFontSize = remember(layer) {
        (25 - layer * 3).px
    }

    val marginTitleBottom = remember(layer) {
        (15 - layer * 10).px
    }

    val marginLeft = remember(layer) {
        (layer * 10).px
    }

    if (aboutBlock.title != null)
        P(
            attrs = Modifier
                .margin(bottom = marginTitleBottom , left = marginLeft)
                .fontFamily(Constants.FONT_FAMILY)
                .fontSize(titleFontSize)
                .fontWeight(FontWeight.Light)
                .color(Theme.Secondary.rgb)
                .toAttrs()

        ) {
            Text(
                aboutBlock.title
            )
        }

    SimpleGrid(
        modifier = Modifier.gap(20.px),
        numColumns = numColumns(base = 1, md = 3)
    ) {

        val showStartImage = aboutBlock.image != null && breakpoint < Breakpoint.MD
        val showEndImage = aboutBlock.image != null && breakpoint >= Breakpoint.MD

        if (showStartImage)
            Image(
                modifier = Modifier.fillMaxWidth().gridColumn(1),
                src = aboutBlock.image!!
            )

        Column(
            modifier = Modifier.fillMaxWidth().gridColumn(1,if (!showStartImage && !showEndImage) 4 else if(breakpoint >= Breakpoint.SM) 3 else 1)
        ) {
            if (aboutBlock.content != null)
                P(
                    attrs = Modifier
                        //.order(if (imageLeft) 1 else 0)
                        .margin(bottom = 25.px, left = marginLeft)
                        .fontFamily(Constants.FONT_FAMILY)
                        .fontWeight(FontWeight.Light)
                        .color(Theme.Secondary.rgb)
                        .toAttrs {
                            ref {
                                it.innerHTML = aboutBlock.content.htmlDescription
                                onDispose { }
                            }
                        }

                )

            aboutBlock.subArticleBlocks.forEach {
                ArticleBlockItem(it, layer = layer + 1)
            }

        }

        if (showEndImage)
            Image(
                modifier = Modifier.fillMaxWidth().gridColumn(3,3),
                src = aboutBlock.image!!
            )

    }

}