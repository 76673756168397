package be.dvlopment.janssenstuinaanleg.components.website

import androidx.compose.runtime.Composable
import be.dvlopment.janssenstuinaanleg.models.Picture
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.px

@Composable
fun PictureGrid(
    portFolioItems: List<Picture>?,
    onPictureClick : (Picture) -> Unit
) {
    SimpleGrid(
        modifier = Modifier
            .id("scrollableContainer")
            .fillMaxWidth()
            .rowGap(10.px)
            .columnGap(10.px),
        numColumns = numColumns(base = 1, md = 2, lg = 3)
    ) {
        portFolioItems?.forEach {
            PictureItem(it){
                onPictureClick(it)
            }
        }
    }
}

@Composable
private fun PictureItem(
    picture: Picture,
    onClick : (Picture) -> Unit
) {
    Image(
        modifier = Modifier
            .fillMaxSize()
            .id("boxParent")
            .aspectRatio(width = 3, height = 2)
            .margin(bottom = 20.px)
            .display(DisplayStyle.Block)
            .onClick {
                onClick(picture)
            },
        src = picture.path,
        alt = "Picture for portfolio"
    )
}