package be.dvlopment.janssenstuinaanleg.models

import be.dvlopment.janssenstuinaanleg.util.Res

class ArticleBlock(
    val title: String? = null,
    val content: String? = null,
    val image: String? = null,
    val subArticleBlocks: List<ArticleBlock> = emptyList()
) {
    companion object {
        val activities = listOf(
            ArticleBlock(
                Res.String.activities_gardenDesign,
                Res.String.activities_gardenDesign_description,
                subArticleBlocks = listOf(
                    ArticleBlock(
                        title = Res.String.activities_unique,
                        content = Res.String.activities_unique_description
                    ),
                    ArticleBlock(
                        title = Res.String.activities_partners,
                        content = Res.String.activities_partners_description
                    )
                ),
                image = Res.Image.tuinaanleg
            ),
            ArticleBlock(
                Res.String.activities_klinkerwerken,
                Res.String.activities_klinkerwerken_description,
                subArticleBlocks = listOf(
                            ArticleBlock(
                                title = Res.String.activities_driveway,
                                content = Res.String.activities_driveway_description
                            ),
                            ArticleBlock(
                                title = Res.String.activities_garden_paths,
                                content = Res.String.activities_garden_paths_description
                            ),
                            ArticleBlock(
                                title = Res.String.activities_renovations,
                                content = Res.String.activities_renovations_description
                            )
                ),
                image = Res.Image.klinkerwerken
            ),
            ArticleBlock(
                Res.String.activities_groen_aanleg,
                Res.String.activities_groenaanleg_description,
                image = Res.Image.groenaanleg
            ),
            ArticleBlock(
                Res.String.activities_wadi,
                Res.String.activities_wadi_description
            ),
            ArticleBlock(
                Res.String.activities_grondwerken,
                Res.String.activities_grondwerken_description
            ),
            ArticleBlock(
                Res.String.activities_scheiden_riolering,
                Res.String.activities_scheiden_riolering_description
            ),
            ArticleBlock(
                Res.String.activities_kleine_verbouwing,
                Res.String.activities_kleine_verbouwing_description,
                image = Res.Image.kleine_verbouwing
            ),
            ArticleBlock(
                Res.String.activities_onderhoud_sportvelden,
                Res.String.activities_onderhoud_sportvelden_description,
                subArticleBlocks = listOf(
                    ArticleBlock(
                        Res.String.activities_grass,
                        Res.String.activities_grass_description
                    ),
                    ArticleBlock(
                        Res.String.activities_bemesting,
                        Res.String.activities_bemesting_description
                    ),
                    ArticleBlock(
                        Res.String.activities_beluchting,
                        Res.String.activities_beluchting_description
                    ),
                    ArticleBlock(
                        Res.String.activities_verticuteren,
                        Res.String.activities_verticuteren_description
                    ),
                    ArticleBlock(
                        Res.String.activities_doorzaaien,
                        Res.String.activities_doorzaaien_description
                    )
                )
            )
        )

        val aboutBlocks = listOf(
            ArticleBlock(
                content = Res.String.about_intro,
                //Res.Image.garden
            ),
            ArticleBlock(
                Res.String.about_creative_experience_title,
                Res.String.about_creative_experience_description
                //Res.Image.garden
            ),
            ArticleBlock(
                Res.String.about_durability_title,
                Res.String.about_durability_description,
                //Res.Image.garden
            )
        )
    }
}