package be.dvlopment.janssenstuinaanleg.pages.admin

import androidx.compose.runtime.Composable
import androidx.compose.runtime.rememberCoroutineScope
import be.dvlopment.janssenstuinaanleg.models.Theme
import be.dvlopment.janssenstuinaanleg.navigation.Screen
import be.dvlopment.janssenstuinaanleg.util.Constants
import be.dvlopment.janssenstuinaanleg.util.Constants.FONT_FAMILY
import be.dvlopment.janssenstuinaanleg.util.Res
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.file.loadDataUrlFromDisk
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import kotlinx.browser.document
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text

@Page("/create/success")
@Composable
fun CreateSuccessPage() {
    val context = rememberPageContext()

    Column(
        modifier = Modifier
            .padding(topBottom = 25.px)
            .fillMaxSize(),
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.Center
    ) {
        P(
            attrs = Modifier
                .margin(bottom = 25.px)
                .fontFamily(Constants.FONT_FAMILY)
                .fontSize(20.px)
                .fontWeight(FontWeight.Light)
                .color(Theme.Secondary.rgb)
                .toAttrs()

        ) {
            Text(
                Res.String.createSuccessTitle
            )
        }

        Button(
            attrs = Modifier
                .margin(bottom = 24.px)
                .width(350.px)
                .height(54.px)
                .backgroundColor(Theme.Primary.rgb)
                .color(Colors.White)
                .borderRadius(r = 4.px)
                .fontFamily(Constants.FONT_FAMILY)
                .fontWeight(FontWeight.Medium)
                .fontSize(14.px)
                .cursor(Cursor.Pointer)
                .onClick {
                    context.router.navigateTo(Screen.AdminHome.route)
                }
                .toAttrs()
        ) {
            SpanText(
                modifier = Modifier.fontFamily(FONT_FAMILY),
                text = Res.String.go_to_home
            )
        }
    }
}

