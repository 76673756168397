import androidx.compose.runtime.CompositionLocalProvider
import com.varabyte.kobweb.core.AppGlobals
import com.varabyte.kobweb.navigation.RoutePrefix
import com.varabyte.kobweb.navigation.Router
import com.varabyte.kobweb.navigation.UpdateHistoryMode
import com.varabyte.kobweb.silk.defer.renderWithDeferred
import kotlinx.browser.document
import kotlinx.browser.window
import org.jetbrains.compose.web.renderComposable

public fun main() {
    RoutePrefix.set("")
    val router = Router()
    com.varabyte.kobweb.core.init.initKobweb(router) { ctx ->
        ctx.router.register("/") { be.dvlopment.janssenstuinaanleg.pages.HomePage() }
        ctx.router.register("/about") { be.dvlopment.janssenstuinaanleg.pages.AboutPage() }
        ctx.router.register("/activities") { be.dvlopment.janssenstuinaanleg.pages.ActivitiesPage()
                }
        ctx.router.register("/admin/") { be.dvlopment.janssenstuinaanleg.pages.admin.HomePage() }
        ctx.router.register("/admin/create") {
                be.dvlopment.janssenstuinaanleg.pages.admin.CreatePage() }
        ctx.router.register("/admin/login") {
                be.dvlopment.janssenstuinaanleg.pages.admin.LoginScreen() }
        ctx.router.register("/admin/project/{id}/edit") {
                be.dvlopment.janssenstuinaanleg.pages.admin.EditProjectPage() }
        ctx.router.register("/contact") { be.dvlopment.janssenstuinaanleg.pages.ContactPage() }
        ctx.router.register("/contact/success") {
                be.dvlopment.janssenstuinaanleg.pages.ContactSuccessPage() }
        ctx.router.register("/create/success") {
                be.dvlopment.janssenstuinaanleg.pages.admin.CreateSuccessPage() }
        ctx.router.register("/portfolio") { be.dvlopment.janssenstuinaanleg.pages.PortfolioPage() }
        ctx.router.register("/project/{id}") {
                be.dvlopment.janssenstuinaanleg.pages.ProjectDetailPage() }

    }
    router.addRouteInterceptor {
        path = path.removeSuffix(".html").removeSuffix(".htm")
    }

    com.varabyte.kobweb.silk.init.additionalSilkInitialization = { ctx ->
        com.varabyte.kobweb.silk.init.initSilkWidgets(ctx)
        com.varabyte.kobweb.silk.init.initSilkWidgetsKobweb(ctx)
        ctx.theme.registerComponentStyle(be.dvlopment.janssenstuinaanleg.styles.AdminProjectStyle)
        ctx.theme.registerComponentStyle(be.dvlopment.janssenstuinaanleg.styles.SidePanelItemStyle)
        ctx.theme.registerComponentStyle(be.dvlopment.janssenstuinaanleg.styles.InputStyle)
        ctx.theme.registerComponentStyle(be.dvlopment.janssenstuinaanleg.styles.dd)
        ctx.theme.registerComponentStyle(be.dvlopment.janssenstuinaanleg.styles.NavigationItemStyle)
        ctx.theme.registerComponentStyle(be.dvlopment.janssenstuinaanleg.styles.LogoStyle)
        ctx.theme.registerComponentStyle(be.dvlopment.janssenstuinaanleg.styles.SocialLinkStyle)
        ctx.theme.registerComponentStyle(be.dvlopment.janssenstuinaanleg.styles.MainButtonStyle)
        ctx.theme.registerComponentStyle(be.dvlopment.janssenstuinaanleg.styles.MainImageStyle)
        ctx.theme.registerComponentStyle(be.dvlopment.janssenstuinaanleg.styles.LoginInputStyle)
        ctx.theme.registerComponentStyle(be.dvlopment.janssenstuinaanleg.styles.PortfolioSectionStyle)
    }

    // For SEO, we may bake the contents of a page in at build time. However, we will
    // overwrite them the first time we render this page with their composable, dynamic
    // versions. Think of this as poor man's hydration :)
    // See also: https://en.wikipedia.org/wiki/Hydration_(web_development)
    router.navigateTo(window.location.href.removePrefix(window.location.origin),
            UpdateHistoryMode.REPLACE)

    val root = document.getElementById("root")!!
    while (root.firstChild != null) { root.removeChild(root.firstChild!!) }

    AppGlobals.initialize(mapOf("title" to "Janssens Tuinaanleg", "MONGODB_URI" to
            "mongodb+srv://dietervaesen:ebO44bmHz5kCgDOK@cluster0.cdmlwro.mongodb.net/"))
    renderComposable(rootElementId = "root") {
        be.dvlopment.janssenstuinaanleg.MyApp {
            router.renderActivePage { renderWithDeferred { it() } }
        }
    }
}
