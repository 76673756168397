package be.dvlopment.janssenstuinaanleg.pages

import androidx.compose.runtime.Composable
import be.dvlopment.janssenstuinaanleg.components.website.PageLayout
import be.dvlopment.janssenstuinaanleg.navigation.Screen
import be.dvlopment.janssenstuinaanleg.util.Res
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.css.percent

@Page
@Composable
fun HomePage() {
    val breakpoint = rememberBreakpoint()
    PageLayout(screen = Screen.Home){
        Image(
            modifier = Modifier.fillMaxWidth(if (breakpoint > Breakpoint.MD) 95.percent else 100.percent),
            src = Res.Image.home,
            alt = "Cover Image"
        )
    }
}
