package be.dvlopment.janssenstuinaanleg.components.website

import androidx.compose.runtime.*
import be.dvlopment.janssenstuinaanleg.models.Theme
import be.dvlopment.janssenstuinaanleg.navigation.Screen
import be.dvlopment.janssenstuinaanleg.site.BuildConfig
import be.dvlopment.janssenstuinaanleg.util.Constants
import be.dvlopment.janssenstuinaanleg.util.Res
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.dom.ref
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.layout.HorizontalDivider
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.css.AlignContent
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text

@Composable
fun PageLayout(
    screen: Screen? = null,
    content: @Composable () -> Unit
) {
    var menuOpened by remember {
        mutableStateOf(false)
    }

    val breakpoint = rememberBreakpoint()

    Box(
        modifier = Modifier.fillMaxSize()
    ) {
        Column(
            modifier = Modifier.fillMaxSize(),
            verticalArrangement = Arrangement.Top,
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            Header(
                screen,
                onMenuClicked = {
                    menuOpened = true
                })

            HorizontalDivider(
                modifier = Modifier.fillMaxWidth().margin(top = 0.px, bottom = 15.px)
                    .backgroundColor(Theme.Secondary.rgb)
            )

            content()

            HorizontalDivider(
                modifier = Modifier.fillMaxWidth().backgroundColor(Theme.Secondary.rgb)
            )

            if (screen != Screen.Home)
                ContactFooter(breakpoint = breakpoint)
        }
        if (menuOpened) {
            OverflowMenu(selectedPage = screen, onMenuClosed = {
                menuOpened = false
            })
        }
    }
}

@Composable
fun ContactFooter(breakpoint: Breakpoint) {

    SimpleGrid(
        modifier = Modifier
            .fillMaxWidth(if (breakpoint >= Breakpoint.MD) 70.percent else 90.percent)
            .padding(bottom = 25.px)
            .gap(rowGap = 10.px, columnGap = 10.px),
        numColumns = numColumns(base = 1, md = 3)
    ) {
        //contact column
        Div {
            P(
                attrs = Modifier
                    .margin(0.px)
                    .fontFamily(Constants.FONT_FAMILY)
                    .fontSize(15.px)
                    .fontWeight(FontWeight.Normal)
                    .color(Theme.Secondary.rgb)
                    .toAttrs()

            ) {
                Text(
                    Res.String.contactBlockTitle
                )
            }

            P(
                attrs = Modifier
                    .margin(0.px)
                    .fontFamily(Constants.FONT_FAMILY)
                    .fontSize(12.px)
                    .fontWeight(FontWeight.Normal)
                    .color(Theme.DarkGray.rgb)
                    .toAttrs()

            ) {
                Text(
                    Res.String.contactPhonenumber
                )
            }

            P(
                attrs = Modifier
                    .margin(0.px)
                    .fontFamily(Constants.FONT_FAMILY)
                    .fontSize(12.px)
                    .fontWeight(FontWeight.Normal)
                    .color(Theme.DarkGray.rgb)
                    .toAttrs()

            ) {
                Text(
                    BuildConfig.MAIL_FROM_ACCOUNT
                )
            }

            P(
                attrs = Modifier
                    .margin(0.px)
                    .fontFamily(Constants.FONT_FAMILY)
                    .fontSize(12.px)
                    .fontWeight(FontWeight.Normal)
                    .color(Theme.DarkGray.rgb)
                    .toAttrs()

            ) {
                Text(
                    Res.String.btwNumber
                )
            }
        }
        Div(
            attrs = Modifier
                .toAttrs()
        ) {
            P(
                attrs = Modifier
                    .margin(0.px)
                    .fontFamily(Constants.FONT_FAMILY)
                    .fontSize(15.px)
                    .fontWeight(FontWeight.Normal)
                    .color(Theme.Secondary.rgb)
                    .toAttrs()

            ) {
                Text(
                    Res.String.contactCompanyName
                )
            }

            P(
                attrs = Modifier
                    .margin(0.px)
                    .fontFamily(Constants.FONT_FAMILY)
                    .fontSize(12.px)
                    .fontWeight(FontWeight.Normal)
                    .color(Theme.DarkGray.rgb)
                    .toAttrs()

            ) {
                Text(
                    Res.String.contactAddressLine1
                )
            }

            P(
                attrs = Modifier
                    .margin(0.px)
                    .fontFamily(Constants.FONT_FAMILY)
                    .fontSize(12.px)
                    .fontWeight(FontWeight.Normal)
                    .color(Theme.DarkGray.rgb)
                    .toAttrs()

            ) {
                Text(
                    Res.String.contactAddressLine2
                )
            }
        }

        P(
            attrs = Modifier
                .margin(0.px)
                .fontFamily(Constants.FONT_FAMILY)
                .fontSize(12.px)
                .fontWeight(FontWeight.Normal)
                .color(Theme.DarkGray.rgb)
                .toAttrs()

        ) {
            Text(
                Res.String.all_rights_reserved
            )
        }
    }
}
