package be.dvlopment.janssenstuinaanleg.pages.admin

import androidx.compose.runtime.*
import be.dvlopment.janssenstuinaanleg.util.*
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext

@Page("project/{id}/edit")
@Composable
fun EditProjectPage() {
    isUserLoggedIn {
        val ctx = rememberPageContext()
        val projectId = ctx.route.params.getValue("id")
        CreateScreen(projectId)
    }
}


