package be.dvlopment.janssenstuinaanleg.pages

import androidx.compose.runtime.Composable
import be.dvlopment.janssenstuinaanleg.components.website.ArticleBlockItem
import be.dvlopment.janssenstuinaanleg.components.website.PageLayout
import be.dvlopment.janssenstuinaanleg.models.ArticleBlock
import be.dvlopment.janssenstuinaanleg.navigation.Screen
import be.dvlopment.janssenstuinaanleg.util.Res
import com.varabyte.kobweb.compose.css.ObjectFit
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Page
@Composable
fun AboutPage() {
    val breakpoint = rememberBreakpoint()
    PageLayout(Screen.About) {
        Column(
            modifier = Modifier.fillMaxWidth(),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {

            Image(
                modifier = Modifier.fillMaxWidth(if (breakpoint >= Breakpoint.MD) 95.percent else 100.percent),
                src = Res.Image.over_small
            )
            Column(
                modifier = Modifier.margin(top = 25.px).fillMaxWidth(if (breakpoint >= Breakpoint.MD) 70.percent else 90.percent)
            ) {
                ArticleBlock.aboutBlocks.forEach { articleBlock ->
                    ArticleBlockItem(articleBlock)
                }
            }
        }
    }
}




