package be.dvlopment.janssenstuinaanleg.styles

import be.dvlopment.janssenstuinaanleg.models.Theme
import be.dvlopment.janssenstuinaanleg.util.Id
import com.varabyte.kobweb.compose.css.CSSTransition
import com.varabyte.kobweb.compose.css.TransitionProperty
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.transition
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import org.jetbrains.compose.web.css.ms

val SidePanelItemStyle by ComponentStyle {
    cssRule(":hover > #${Id.sidePanelFaIcon}") {
        Modifier.color(Colors.White.toRgb())
    }

    cssRule(" > #${Id.sidePanelFaIcon}") {
        Modifier
            .transition(CSSTransition(property = TransitionProperty.All, duration = 300.ms))
            .color(Theme.Gray.rgb)
    }

    cssRule(":hover > #${Id.sidePanelItemText}") {
        Modifier.color(Colors.White.toRgb())
    }

    cssRule(" > #${Id.sidePanelItemText}") {
        Modifier
            .transition(CSSTransition(property = TransitionProperty.All, duration = 300.ms))
            .color(Theme.Gray.rgb)
    }




}