package be.dvlopment.janssenstuinaanleg.styles

import be.dvlopment.janssenstuinaanleg.models.Theme
import com.varabyte.kobweb.compose.css.CSSTransition
import com.varabyte.kobweb.compose.css.Visibility
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import org.jetbrains.compose.web.css.ms
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

val AdminProjectStyle by ComponentStyle {
    //targeting child element with _id iconBox
    cssRule(" > #boxParent > #greenOverlay") {
        Modifier
            .height(0.px)
            .transition(CSSTransition("height", duration = 500.ms))
    }

    cssRule(":hover > #boxParent > #greenOverlay") {
        Modifier.fillMaxSize()
    }

    cssRule(" > #boxParent > #greenOverlay > #portfolioItemInfo") {
        Modifier
            .visibility(Visibility.Hidden)
    }

    cssRule(":hover > #boxParent > #greenOverlay > #portfolioItemInfo") {
        Modifier
            .visibility(Visibility.Visible)
    }

    cssRule(" > #portfolioTitle") {
        Modifier
            .color(Theme.Secondary.rgb)
            .translateX(0.percent)
            .transition(CSSTransition("color", duration = 200.ms))
            .transition(CSSTransition("translate", duration = 200.ms))
    }

    cssRule(":hover > #portfolioTitle") {
        Modifier
            .color(Theme.Primary.rgb)
            .translateX(5.percent)
    }

    cssRule(" > #portfolioDesc") {
        Modifier
            .translateX(0.percent)
            .transition(CSSTransition("translate", duration = 200.ms))
    }

    cssRule(":hover > #portfolioDesc") {
        Modifier
            .translateX(5.percent)
    }
}