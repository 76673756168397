package be.dvlopment.janssenstuinaanleg.navigation

import be.dvlopment.janssenstuinaanleg.constants.Constants.POST_ID_PARAM
import be.dvlopment.janssenstuinaanleg.constants.Constants.QUERY_PARAM
import be.dvlopment.janssenstuinaanleg.constants.Constants.UPDATED_PARAM
import be.dvlopment.janssenstuinaanleg.util.Res

sealed class Screen(
    val route: String,
    val title: String? = null,
    val faIconName : String? = null
) {
    companion object {
        val websiteDestinations = listOf(
            Home,
            Portfolio,
            Activities,
            About,
            Contact
        )

        val adminPanelDestinations = listOf(
            AdminHome,
            AdminCreate,
            AdminLogout
        )
    }


    data object Home : Screen(
        route = "/",
        title = Res.String.home
    )

    data object Portfolio : Screen(
        route = "/portfolio",
        title = Res.String.portfolio
    )

    data object Activities : Screen(
        route = "/activities",
        title = Res.String.activities
    )

    data object About : Screen(
        route = "/about",
        title = Res.String.about
    )

    data object Contact : Screen(
        route = "/contact",
        title = Res.String.contact
    )


    // admin
    data object AdminHome : Screen(
        route = "/admin/",
        faIconName = "house",
        title = Res.String.home
    )

    data object AdminLogin : Screen(
        route = "/admin/login"
    )

    data object AdminCreate : Screen(
        route = "/admin/create",
        faIconName = "circle-plus",
        title = Res.String.create
    ) {
        fun passPostId(id: String) = "/admin/create?${POST_ID_PARAM}=$id"
    }

    data object AdminSuccess : Screen(
        route = "/admin/success"
    ) {
        fun postUpdated() = "/admin/success?${UPDATED_PARAM}=true"
    }

    data object AdminLogout : Screen(
        route = "/admin/logout",
        faIconName = "sign-out",
        title = Res.String.signout
    )
}