package be.dvlopment.janssenstuinaanleg.pages

import androidx.compose.runtime.Composable
import androidx.compose.runtime.rememberCoroutineScope
import be.dvlopment.janssenstuinaanleg.models.Theme
import be.dvlopment.janssenstuinaanleg.util.Constants
import be.dvlopment.janssenstuinaanleg.util.Res
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text

@Page("/contact/success")
@Composable
fun ContactSuccessPage() {
    val breakpoint = rememberBreakpoint()
    val scope = rememberCoroutineScope()

    Column(
        modifier = Modifier
            .padding(topBottom = 25.px)
            .fillMaxSize(),
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.Center
    ) {

        Image(
            src = Res.Image.logo,
            alt = "Janssenstuinaanleg logo"
        )

        P(
            attrs = Modifier
                .margin(bottom = 25.px)
                .fontFamily(Constants.FONT_FAMILY)
                .fontSize(20.px)
                .fontWeight(FontWeight.Light)
                .color(Theme.Secondary.rgb)
                .toAttrs()

        ) {
            Text(
                Res.String.contactSuccessTitle
            )
        }

        P(
            attrs = Modifier
                .margin(bottom = 25.px)
                .fontFamily(Constants.FONT_FAMILY)
                .fontSize(15.px)
                .fontWeight(FontWeight.Light)
                .color(Theme.Secondary.rgb)
                .toAttrs()

        ) {
            Text(
                Res.String.contactSuccessMessage
            )
        }
    }
}

