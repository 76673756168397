package be.dvlopment.janssenstuinaanleg.styles

import be.dvlopment.janssenstuinaanleg.models.Theme
import com.varabyte.kobweb.compose.css.CSSTransition
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.boxShadow
import com.varabyte.kobweb.compose.ui.modifiers.transition
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.focus
import com.varabyte.kobweb.silk.components.style.hover
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.ms
import org.jetbrains.compose.web.css.px


@OptIn(ExperimentalComposeWebApi::class)
val InputStyle: ComponentStyle by ComponentStyle {
    base {
        Modifier
            .border {
                width(2.px)
                color(Theme.LightGray.rgb)
                style(LineStyle.Solid)
            }
            .boxShadow(0.px, 0.px, 0.px, 0.px, null)
            .transition(CSSTransition(property = "border", duration = 200.ms))
        //use .ALL because we are animating multiple properties
    }

    hover {
        Modifier
            .border {
                width(2.px)
                color(Theme.Primary.rgb)
                style(LineStyle.Solid)
            }
            .boxShadow(0.px, 0.px, 0.px, 0.px, null)
    }

    focus {
        Modifier.border {
            width(2.px)
            color(Theme.Primary.rgb)
            style(LineStyle.Solid)
        }.boxShadow(0.px, 0.px, 0.px, 0.px, null)
    }
}