package be.dvlopment.janssenstuinaanleg.components

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.classNames
import com.varabyte.kobweb.compose.ui.modifiers.role
import com.varabyte.kobweb.compose.ui.toAttrs
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.Span

@Composable
fun LoadingSpinner() {
    Div(attrs = Modifier.classNames("spinner-border","text-success").role("status").toAttrs()) {
        Span(attrs = Modifier.classNames("sr-only").toAttrs())
    }
}