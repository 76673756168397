package be.dvlopment.janssenstuinaanleg.components.website

import androidx.compose.runtime.Composable
import be.dvlopment.janssenstuinaanleg.navigation.Screen
import be.dvlopment.janssenstuinaanleg.styles.NavigationItemStyle
import be.dvlopment.janssenstuinaanleg.util.Constants
import be.dvlopment.janssenstuinaanleg.util.Res
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextDecorationLine
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.*
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.FaBars
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Composable
fun Header(screen: Screen? = null, onMenuClicked: () -> Unit) {
    val breakpoint = rememberBreakpoint()
    Row(
        modifier = Modifier.fillMaxWidth(if (breakpoint > Breakpoint.MD) 95.percent else 90.percent)
            .margin(topBottom = 25.px),
        horizontalArrangement = Arrangement.SpaceBetween,
        verticalAlignment = Alignment.CenterVertically
    ) {
        LeftSide(breakpoint, onMenuClicked)
        if (breakpoint > Breakpoint.MD) RightSide(screen)
    }
}

@Composable
fun LeftSide(
    breakpoint: Breakpoint, menuClicked: () -> Unit
) {
    Row(verticalAlignment = Alignment.CenterVertically) {
        if (breakpoint <= Breakpoint.MD) FaBars(
            modifier = Modifier
                .margin(right = 15.px)
                .onClick {
                    menuClicked()
                }, size = IconSize.XL
        )
        Image(
            modifier = Modifier.height(80.px), src = Res.Image.logo, alt = "Logo Icon"
        )
    }
}

@Composable
fun RightSide(selectedPage: Screen? = null) {
    Row(
        modifier = Modifier.padding(all = 20.px), horizontalArrangement = Arrangement.End
    ) {
        val Screen = Screen.websiteDestinations.toTypedArray()
        Screen.forEach { screen ->
            Link(
                modifier = NavigationItemStyle
                    .toModifier()
                    .thenIf(screen == selectedPage, Modifier.classNames("active"))
                    .padding(right = 30.px)
                    .fontFamily(Constants.FONT_FAMILY)
                    .fontSize(18.px)
                    .fontWeight(FontWeight.Light)
                    .textDecorationLine(TextDecorationLine.None),
                path = screen.route,
                text = screen.title
            )
        }

        //SocialBar(false)
    }
}