package be.dvlopment.janssenstuinaanleg.models

import be.dvlopment.janssenstuinaanleg.constants.htmlDescription
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Project(
    @SerialName(value = "_id")
    val _id: String = "",
    val pictures: List<Picture> = emptyList(),
    val title: String,
    val description: String = "",
    val location: String = "",
    val architect: String = "",
    val type: String = "",
    val date: String = "",
    val order: Int = 0
){
    val htmlDescription get() = description.htmlDescription
}


