package be.dvlopment.janssenstuinaanleg.pages.admin

import androidx.compose.runtime.*
import be.dvlopment.janssenstuinaanleg.ApiResponse
import be.dvlopment.janssenstuinaanleg.components.LoadingSpinner
import be.dvlopment.janssenstuinaanleg.components.admin.AdminPageLayout
import be.dvlopment.janssenstuinaanleg.models.Picture
import be.dvlopment.janssenstuinaanleg.models.Project
import be.dvlopment.janssenstuinaanleg.models.Theme
import be.dvlopment.janssenstuinaanleg.styles.InputStyle
import be.dvlopment.janssenstuinaanleg.util.*
import be.dvlopment.janssenstuinaanleg.util.Constants.FONT_FAMILY
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.Height
import com.varabyte.kobweb.compose.css.Width
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.attrsModifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.*
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import kotlinx.browser.document
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Input
import org.jetbrains.compose.web.dom.Text
import org.jetbrains.compose.web.dom.TextArea
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.HTMLTextAreaElement
import kotlin.io.encoding.ExperimentalEncodingApi

@Page
@Composable
fun CreatePage() {
    isUserLoggedIn {
        CreateScreen()
    }
}

data class CreateOrEditProjectPageUiState(
    var id: String? = null,
    var title: String = "",
    var description: String = "",
    var location: String = "",
    var architect: String = "",
    var type: String = "",
    var date: String = "",
    var images: List<Picture> = emptyList(),
    var error: String? = null
) {
    val isEditMode get() = !id.isNullOrEmpty()
    val hasInput get() = title.isNotEmpty() || description.isNotEmpty() || location.isNotEmpty() || architect.isNotEmpty() || type.isNotEmpty() || date.isNotEmpty()
    val requiredFieldsFilledIn get() = title.isNotEmpty() && description.isNotEmpty() && location.isNotEmpty()
}

@OptIn(ExperimentalEncodingApi::class)
@Composable
fun CreateScreen(projectId: String? = null) {

    val breakpoint = rememberBreakpoint()
    val scope = rememberCoroutineScope()
    val context = rememberPageContext()

    var apiResponse by remember {
        mutableStateOf<ApiResponse<Project>>(ApiResponse.Idle)
    }

    var editProjectResponse by remember {
        mutableStateOf<ApiResponse<Boolean>>(ApiResponse.Success(false))
    }

    var uiState by remember(apiResponse) {
        mutableStateOf(
            if (apiResponse is ApiResponse.Success<Project>) {
                val response = apiResponse as ApiResponse.Success<Project>
                CreateOrEditProjectPageUiState(
                    id = response.data._id,
                    title = response.data.title,
                    description = response.data.description,
                    location = response.data.location,
                    architect = response.data.architect,
                    type = response.data.type,
                    date = response.data.date,
                    images = response.data.pictures
                )
            } else
                CreateOrEditProjectPageUiState()
        )
    }

    if (projectId != null)
        LaunchedEffect(Unit) {
            scope.launch {
                apiResponse = getProjectById(projectId)
            }
        }



    AdminPageLayout {
        Box(
            modifier = Modifier
                .fillMaxSize()
                .margin(topBottom = 50.px)
                .padding(left = if (breakpoint > Breakpoint.MD) Constants.SIDE_PANEL_WIDTH.px else 0.px),
            contentAlignment = Alignment.TopCenter
        ) {
            Column(
                modifier = Modifier
                    .fillMaxWidth()
                    .maxWidth(800.px)
                    .borderRadius(5.px)
                    .padding(leftRight = 20.px, bottom = 20.px)
                    .backgroundColor(Colors.LightGray),
                verticalArrangement = Arrangement.Top,
                horizontalAlignment = Alignment.CenterHorizontally
            ) {

                if (uiState.isEditMode)
                    Text("Edit mode")
                //title
                Input(
                    type = InputType.Text,
                    attrs = InputStyle.toModifier()
                        .id(Id.titleInput)
                        .classNames("form-control")
                        .fillMaxWidth()
                        .height(54.px)
                        .margin(topBottom = 12.px)
                        .padding(leftRight = 20.px)
                        .fontFamily(FONT_FAMILY)
                        .fontSize(15.px)
                        .borderRadius(r = 4.px)
                        .toAttrs {
                            attr("placeholder", Res.String.title)
                            attr("value", uiState.title)
                        }
                )

                TextArea(
                    attrs = InputStyle.toModifier()
                        .id(Id.descriptionInput)
                        .classNames("form-control")
                        .fontFamily(FONT_FAMILY)
                        .margin(bottom = 10.px)
                        .height(150.px).attrsModifier {
                            attr("placeholder", Res.String.description)
                            attr("required", "true")
                            attr("name", "message")
                        }
                        .backgroundColor(Theme.LighterGray.rgb)
                        .toAttrs {
                            defaultValue(uiState.description)
                        }
                )
                //architect
                Input(
                    type = InputType.Text,
                    attrs = InputStyle.toModifier()
                        .id(Id.architectInput)
                        .classNames("form-control")
                        .fillMaxWidth()
                        .height(54.px)
                        .margin(bottom = 12.px)
                        .padding(leftRight = 20.px)
                        .fontFamily(FONT_FAMILY)
                        .fontSize(15.px)
                        .borderRadius(r = 4.px)
                        .toAttrs {
                            attr("placeholder", Res.String.architect)
                            attr("value", uiState.architect)
                        }
                )
                //location
                Input(
                    type = InputType.Text,
                    attrs = InputStyle.toModifier()
                        .id(Id.locationInput)
                        .classNames("form-control")
                        .fillMaxWidth()
                        .height(54.px)
                        .margin(bottom = 12.px)
                        .padding(leftRight = 20.px)
                        .fontFamily(FONT_FAMILY)
                        .fontSize(15.px)
                        .borderRadius(r = 4.px)
                        .toAttrs {
                            attr("placeholder", Res.String.location)
                            attr("value", uiState.location)
                        }
                )
                //type
                Input(
                    type = InputType.Text,
                    attrs = InputStyle.toModifier()
                        .id(Id.typeInput)
                        .classNames("form-control")
                        .fillMaxWidth()
                        .height(54.px)
                        .margin(bottom = 12.px)
                        .padding(leftRight = 20.px)
                        .fontFamily(FONT_FAMILY)
                        .fontSize(15.px)
                        .borderRadius(r = 4.px)
                        .toAttrs {
                            attr("placeholder", Res.String.type)
                            attr("value", uiState.type)
                        }

                )

                //date
                Input(
                    type = InputType.Text,
                    attrs = InputStyle.toModifier()
                        .id(Id.dateInput)
                        .classNames("form-control")
                        .fillMaxWidth()
                        .height(54.px)
                        .margin(bottom = 12.px)
                        .padding(leftRight = 20.px)
                        .fontFamily(FONT_FAMILY)
                        .fontSize(15.px)
                        .borderRadius(r = 4.px)
                        .toAttrs {
                            attr("placeholder", Res.String.date)
                            attr("value", uiState.date)
                        }
                )

                ImageUploader(
                    onPicturesSelected = { loadedFiles ->
                        try {
                            val files = loadedFiles.filter { it.result is LoadedFile.LoadResult.Success<String> }
                            val fileResults = files.map {
                                Picture(
                                    path = it.context.filename,
                                    content = (it.result as LoadedFile.LoadResult.Success<String>).contents
                                )
                            }
                            (document.getElementById(Id.headerImageInput) as HTMLInputElement).value =
                                fileResults.joinToString { it.path }
                            val existingImages = uiState.images.toMutableList()
                            existingImages.addAll(fileResults)
                            uiState = uiState.copy(images = existingImages)
                        } catch (e: Exception) {
                            uiState.error = e.message
                        }
                    }
                )

                PicturesPreview(
                    pictures = uiState.images
                ) { image ->
                    val images = uiState.images.toMutableList()
                    images.removeAll { it.path == image }
                    uiState = uiState.copy(images = images)
                }

                uiState.error?.let {
                    ErrorMessage(it)
                }

                if (editProjectResponse == ApiResponse.Idle) {
                    LoadingSpinner()
                } else
                    Button(
                        attrs = Modifier
                            .margin(bottom = 24.px)
                            .width(350.px)
                            .height(54.px)
                            .backgroundColor(Theme.Primary.rgb)
                            .color(Colors.White)
                            .borderRadius(r = 4.px)
                            .fontFamily(FONT_FAMILY)
                            .fontWeight(FontWeight.Medium)
                            .fontSize(14.px)
                            .cursor(Cursor.Pointer)
                            .onClick {
                                uiState = uiState.copy(
                                    title = (document.getElementById(Id.titleInput) as HTMLInputElement).value,
                                    description = (document.getElementById(Id.descriptionInput) as HTMLTextAreaElement).value,
                                    architect = (document.getElementById(Id.architectInput) as HTMLInputElement).value,
                                    location = (document.getElementById(Id.locationInput) as HTMLInputElement).value,
                                    type = (document.getElementById(Id.typeInput) as HTMLInputElement).value,
                                    date = (document.getElementById(Id.dateInput) as HTMLInputElement).value
                                )

                                if (uiState.requiredFieldsFilledIn) {
                                    val pictures = uiState.images.mapIndexed { index, image ->
                                        Picture(
                                            _id = image._id,
                                            path = image.path,
                                            content = image.content ?: "",
                                            isHeader = index == 0
                                        )
                                    }

                                    var project = Project(
                                        title = uiState.title,
                                        description = uiState.description,
                                        architect = uiState.architect,
                                        location = uiState.location,
                                        type = uiState.type,
                                        date = uiState.date,
                                        pictures = pictures
                                    )

                                    if (uiState.isEditMode)
                                        project = project.copy(_id = uiState.id!!)

                                    scope.launch {
                                        editProjectResponse = ApiResponse.Idle
                                        val result =
                                            if (uiState.isEditMode) updateProject(project = project) else addProject(
                                                project = project
                                            )

                                        when (result) {
                                            is ApiResponse.Success<Boolean> -> if (result.data) context.router.navigateTo(
                                                "/create/success"
                                            )

                                            is ApiResponse.Error -> uiState = uiState.copy(error = result.errorMessage)
                                        }
                                        editProjectResponse = result
                                    }
                                } else {
                                    uiState = uiState.copy(error = Res.String.requiredFieldsError)
                                }
                            }
                            .toAttrs()
                    ) {
                        SpanText(text = if (uiState.isEditMode) Res.String.save_changes else Res.String.create)
                    }
            }
        }
    }
}

@Composable
fun ErrorMessage(
    error: String
) {
    Row(
        modifier = Modifier.gap(10.px).margin(bottom = 20.px),
        verticalAlignment = Alignment.CenterVertically
    ) {
        FaCircleExclamation()

        SpanText(
            modifier = Modifier.fontFamily(FONT_FAMILY),
            text = error
        )
    }
}


@Composable
fun PicturesPreview(
    pictures: List<Picture>,
    deleteImage: (String) -> Unit
) {
    SimpleGrid(
        numColumns(1, md = 4),
        modifier = Modifier.fillMaxWidth().gap(10.px).margin(topBottom = 20.px)
    ) {
        pictures.forEach { holder ->
            Box(
                modifier = Modifier.fillMaxWidth().aspectRatio(1)
            ) {
                Image(
                    modifier = Modifier.fillMaxSize(),
                    src = holder.content ?: holder.path
                )
                FaTrash(
                    modifier = Modifier.size(20.px).margin(10.px).align(Alignment.TopEnd).onClick {
                        deleteImage(holder.path)
                    }
                )
            }
        }
    }
}

@Composable
fun ImageUploader(
    onPicturesSelected: (List<LoadedFile<String>>) -> Unit
) {
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .margin(bottom = 20.px)
            .height(54.px)
    ) {
        Input(
            type = InputType.Text,
            attrs = Modifier
                .id(Id.headerImageInput)
                .classNames("form-control")
                .fillMaxSize()
                .margin(right = 12.px)
                .padding(leftRight = 20.px)
                .borderRadius(r = 4.px)
                .fontFamily(FONT_FAMILY)
                .fontSize(16.px)
                .disabled(true)
                .toAttrs {
                    attr("placeholder", Res.String.pictures)
                    //attr("value", thumbnail)
                }
        )
        Button(
            attrs = Modifier
                .onClick {
                    document.loadMultipleDataUrlFromDisk(
                        accept = "image/png, image/jpeg",
                        onLoaded = {
                            onPicturesSelected(it)
                        })
                }
                .padding(leftRight = 24.px)
                .borderRadius(r = 4.px)
                .fontFamily(FONT_FAMILY)
                .fontWeight(FontWeight.Medium)
                .fontSize(14.px)
                .toAttrs()
        ) {
            SpanText(text = Res.String.uploaden)
        }
    }
}

