package be.dvlopment.janssenstuinaanleg.components.admin

import androidx.compose.runtime.*
import be.dvlopment.janssenstuinaanleg.models.Theme
import be.dvlopment.janssenstuinaanleg.navigation.Screen
import be.dvlopment.janssenstuinaanleg.styles.SidePanelItemStyle
import be.dvlopment.janssenstuinaanleg.util.Constants.FONT_FAMILY
import be.dvlopment.janssenstuinaanleg.util.Constants.SIDE_PANEL_WIDTH
import be.dvlopment.janssenstuinaanleg.util.Id
import be.dvlopment.janssenstuinaanleg.util.Res
import be.dvlopment.janssenstuinaanleg.util.logout
import com.varabyte.kobweb.compose.css.CSSTransition
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.functions.opacity
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.core.PageContext
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.*
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.*


@Composable
fun AdminSidePanel(onMenuClick: () -> Unit) {
    val breakpoint = rememberBreakpoint()
    if (breakpoint > Breakpoint.MD)
        AdminSidePanelInternal()
    else
        CollapsedAdminSidePanel(onMenuClick = onMenuClick)
}

@Composable
private fun AdminSidePanelInternal() {

    val adminDestinations = remember {
        Screen.adminPanelDestinations
    }
    Column(
        modifier = Modifier
            .padding(leftRight = 20.px, topBottom = 30.px)
            .fillMaxHeight()
            .maxWidth(SIDE_PANEL_WIDTH.px)
            .height(100.vh)
            .position(Position.Fixed)
            .backgroundColor(Theme.Primary.rgb)
            .zIndex(9)
    ) {
        Image(
            modifier = Modifier.fillMaxWidth(90.percent),
            src = Res.Image.logo,
            alt = "logo"
        )
        SidePanelItems()
    }
}

@Composable
fun SidePanelItem(
    screen: Screen,
    selected: Boolean,
    onClick: () -> Unit
) {
    Row(
        modifier = SidePanelItemStyle
            .toModifier()
            .margin(topBottom = 10.px)
            .cursor(Cursor.Pointer)
            .onClick { onClick() },
        verticalAlignment = Alignment.CenterVertically
    ) {

        FaIcon(
            screen.faIconName ?: "",
            modifier = Modifier
                .id(Id.sidePanelFaIcon)
                .margin(right = 5.px)
                .thenIf(
                    condition = selected,
                    other = Modifier.color(Colors.White.toRgb())
                ),
            size = IconSize.LG,
            style = IconCategory.SOLID
        )

        SpanText(
            modifier = Modifier
                .id(Id.sidePanelItemText)
                .fontFamily(FONT_FAMILY)
                .fontSize(15.px)
                .thenIf(
                    condition = selected,
                    other = Modifier.color(Colors.White.toRgb())
                ),
            text = screen.title ?: ""
        )
    }
}

@Composable
private fun SidePanelItems() {
    val context = rememberPageContext()

    val adminDestinations = remember {
        Screen.adminPanelDestinations
    }

    SpanText(
        modifier = Modifier
            .fontFamily(FONT_FAMILY)
            .fontSize(18.px)
            .margin(topBottom = 10.px)
            .fillMaxWidth()
            .color(Colors.White),
        text = "Portfolio management"
    )

    adminDestinations.forEach {
        SidePanelItem(
            screen = it,
            selected = context.route.path == it.route
        ) {
            if (it == Screen.AdminLogout) {
                logout()
                context.router.navigateTo(Screen.AdminLogin.route)
            } else
                context.router.navigateTo(it.route)
        }
    }
}

@Composable
private fun CollapsedAdminSidePanel(onMenuClick: () -> Unit) {
    Row(
        modifier = Modifier
            .backgroundColor(Theme.Primary.rgb)
            .fillMaxWidth()
            .height(100.px)
            .padding(leftRight = 15.px),
        verticalAlignment = Alignment.CenterVertically
    ) {
        FaBars(
            modifier = Modifier
                .margin(right = 15.px)
                .color(Colors.White.toRgb())
                .cursor(Cursor.Pointer)
                .onClick {
                    onMenuClick()
                },
            size = IconSize.XL
        )

        Image(
            modifier = Modifier.width(70.px),
            src = Res.Image.logo,
            alt = "Logo"
        )
    }
}

@Composable
fun AdminOverflowSidePanel(onMenuClose: () -> Unit) {
    val breakpoint = rememberBreakpoint()
    var translateX by remember{ mutableStateOf((-100).percent) }
    var opacity by remember{ mutableStateOf(0.percent) }
    val scope = rememberCoroutineScope()


    LaunchedEffect(key1 = breakpoint){
        translateX = 0.percent
        opacity = 100.percent
        if(breakpoint > Breakpoint.MD){
            scope.launch {
                translateX = (-100).percent
                opacity = 0.percent
                delay(500)
                onMenuClose()
            }
        }
    }

    Box(
        modifier = Modifier
            .fillMaxWidth()
            .height(100.vh)
            .opacity(opacity)
            .position(Position.Fixed)
            .transition(CSSTransition("opacity", duration = 300.ms))
            .zIndex(9)
            .backgroundColor(Theme.HalfBlack.rgb)
            .onClick { onMenuClose() }
    ) {
        Column(
            modifier = Modifier
                .fillMaxHeight()
                .padding(leftRight = 10.px)
                .translateX(translateX)
                .transition(CSSTransition("translate", duration = 300.ms))
                .width(if (breakpoint < Breakpoint.MD) 50.percent else 25.percent)
                .backgroundColor(Theme.Primary.rgb)
        ) {
            Row(
                modifier = Modifier.margin(bottom = 24.px).padding(topBottom = 10.px),
                verticalAlignment = Alignment.CenterVertically
            ) {
                FaXmark(
                    modifier = Modifier
                        .margin(right = 20.px)
                        .color(Colors.White)
                        .onClick {
                            scope.launch {
                                translateX = (-100).percent
                                opacity = 0.percent
                                delay(500)
                                onMenuClose()
                            }
                        },
                    size = IconSize.LG
                )

                Image(
                    modifier = Modifier.width(100.px),
                    src = Res.Image.logo,
                    alt = "Logo"
                )
            }

            SidePanelItems()
        }

    }
}